@import "~assets/styles/common";

.axiom-global-search-container {
  position: relative;

  .global-search {
    .axiom-search {
      input {
        border: $rem1 solid $transparent;

        &:focus {
          border-color: $axiom-primary-color;
        }
      }
    }
  }
}
