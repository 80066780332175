@import "~assets/styles/common";

.filter-pill-container {
  @include flex(center);

  &.is-projects {
    .filter-dropdown {
      .dropdown {
        width: rem(205);

        .filter-cb {
          label {
            text-transform: capitalize;

            &[for="cb-ios-ios"] {
              text-transform: uppercase;

              &::first-letter {
                text-transform: lowercase;
              }
            }
          }
        }
      }
    }
  }

  &.selected-filters .filter-icon svg rect ~ g {
    stroke: $signin-heading-color;
  }
}
