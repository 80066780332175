@import "~assets/styles/common";

.user-indicator-container {
  @include flex-center;

  .indicator-wrapper:not(:last-child) {
    margin-right: rem(48);
  }

  .indicator-wrapper {
    @include flex-center;

    .icon {
      width: rem(16);
      height: rem(16);
      border-radius: 50%;
      margin-right: $rem12;

      &.internal {
        background-color: $axiom-primary-color;
      }

      &.external {
        background-color: $external-users-indicator;
      }
    }

    .text {
      @include fm(16);
      color: $primary-text-color;
    }

    &.light {
      opacity: 0.4;
    }
  }
}
