@import "~assets/styles/common";

.axiom-search-list-container {
  .list-container {
    position: relative;

    .axiom-list-container {
      width: 100%;
      top: calc(100% + #{$rem5});
      box-shadow: 0 $rem7 rem(26) $list-container-box-shadow;
      border-radius: $rem8;
    }

    .arrow {
      position: absolute;
      top: rem(23);
      right: rem(17);
      display: inline-block;
      height: inherit;
      margin-left: $rem14;
      pointer-events: none;

      svg {
        width: $rem14;
      }

      .name-arrow-down,
      .name-arrow-up {
        @include flex-center;
      }

      .name-arrow-up {
        svg {
          position: relative;
          top: rem(-1);

          path {
            fill: $signin-heading-color;
          }
        }
      }
    }
  }
}
