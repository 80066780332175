@import "~assets/styles/common";

.axiom-image-upload-wrapper {
  .image-upload-wrapper.highlight-wrapper {
    border: dashed rem(4) $axiom-primary-color;

    svg g g use {
      fill: $axiom-primary-color;
    }
  }

  .image-upload-wrapper {
    @include flex(center, center);
    width: rem(160);
    height: rem(160);
    border-radius: rem(16);
    border: dashed rem(4) $image-background;

    .image-upload-container {
      width: rem(136);
      height: rem(136);
      border-radius: rem(8);
      background-color: $image-background;

      input[type="file"] {
        display: none;
      }

      label {
        cursor: pointer;
        img {
          height: rem(136);
          width: rem(136);
          border-radius: rem(8);
        }
      }

      .container .custom-file-upload {
        border-radius: rem(8);
        height: rem(136);
        width: rem(136);
        display: inline-block;
        cursor: pointer;
        background: inherit;

        .name-upload-icon,
        .name-feedback-upload-icon svg {
          height: rem(44);
          width: rem(44);
        }
        p {
          @include fm(10);
          color: $cancel-button-color;
          text-align: center;
        }
        .img-wrap {
          @include flex(center, center);
          overflow: hidden;
          margin-top: rem(32);
          margin-bottom: rem(16);
          border-radius: inherit;

          .name-upload-icon {
            svg {
              width: rem(44);
            }
          }

          & > div {
            @include flex-center;
          }
        }
      }
    }

    &:hover {
      @extend .highlight-wrapper;
    }

    &.app-upload {
      .image-upload-container {
        position: relative;

        .upload-file-text {
          @include fsb(16);
          @include flex-center;
          color: $input-text-color;
        }

        .img-wrap {
          svg {
            width: rem(48);

            g path:nth-child(2) {
              fill: $axiom-input-label-color;
              stroke: $axiom-input-label-color;
            }
          }
        }

        .preview {
          background-color: $axiom-primary-color;

          .img-wrap {
            svg {
              g path:nth-child(2) {
                fill: $white-color;
                stroke: $white-color;
              }
            }
          }
        }

        .upload-status {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: $rem7;

          .upload,
          .uploaded {
            @include flex-center;
          }

          .uploading {
            @include fsb(16);
            @include flex-center;
            color: $axiom-input-label-color;
          }

          button {
            @include fsb(16);
            border: $axiom-input-label-color;
            color: $white-color;
            background-color: $axiom-primary-op-7;
            width: auto;
            height: auto;
            padding: $rem4 rem(16);
            text-transform: capitalize;
          }
        }
      }
    }

    &.feedback-icon {
      .container {
        .custom-file-upload {
          .img-wrap {
            margin: 0;
            height: 100%;
          }
        }
      }
    }

    &.disabled {
      .preview {
        pointer-events: none;
      }

      .container {
        cursor: default;
      }
    }
  }

  .image-border {
    border: solid rem(4) $image-background;
    &:hover {
      border: solid rem(4) $image-background;
    }
  }
}
