@import "~assets/styles/mixins/fonts.scss";
@import "./colors";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}

:root {
  // The value of these variables will be changed for desktop view (in function `isMobile` in common.js)
  --viewport-width: 100vw;
  --viewport-height: 100vh;
}

$viewport-width: var(--viewport-width);
$viewport-height: var(--viewport-height);

body {

  &.is-desktop {
    width: $viewport-width;
    height: $viewport-height;
    overflow: hidden;
    -moz-transform: scale(0.7, 0.7);
    transform-origin: 0 0;
    zoom: 0.7;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

/* scrollbar width */
::-webkit-scrollbar {
  width: 5px;
}

/* scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: $close-btn-color;
}

.empty-list {
  @include fsb(22);
  color: $signin-heading-color;
}

// toggle component styles
.react-toggle {
  .react-toggle-thumb {
    width: rem(16);
    height: rem(16);
    top: 2px;
    border: 2px solid $axiom-input-label-color;
    box-shadow: none !important;
    transform: translateX(0);
  }

  .react-toggle-track {
    width: rem(40);
    height: rem(20);
    background-color: $axiom-input-label-color !important;
  }

  &.react-toggle--checked {
    .react-toggle-track {
      background-color: $axiom-primary-color !important;
    }

    .react-toggle-thumb {
      left: rem(0);
      border-color: $axiom-primary-color;
      transform: translateX(140%);
    }
  }
}

// for laptop with small screen (1280px) reduce the width of content to prevent clipping
@media screen and (max-width: 1280px) {
  .axiom-build-list-view-container {
    width: rem(512);
  }

  .axiom-build-details-container {
    width: rem(648);
  }
}