@import "~assets/styles/common";

.user-details-project-wrapper {
  min-height: rem(142);
  margin-bottom: rem(40);

  .title-button-wrapper {
    @include flex(center, space-between);
    margin-bottom: rem(22);

    .add-to {
      .button-add {
        height: rem(40);
        width: rem(40);
      }
    }
    .project-add,
    .group-add {
      svg g {
        rect {
          fill: $white-color;
          stroke: $axiom-primary-color;
        }
        g {
          stroke: $axiom-primary-color;
        }
      }
    }
    .project-close,
    .group-close {
      svg g {
        rect {
          fill: $white-color;
          stroke: $axiom-primary-color;
        }
        g {
          stroke: $axiom-primary-color;
          :nth-child(2) {
            display: none;
          }
        }
      }
    }
    .title-count-wrapper {
      @include flex(center, flex-start);

      .project-title {
        @include fsb(20);
        color: $signin-heading-color;
        margin-right: rem(10);
        position: relative;
      }

      .project-count {
        @include fsb(14);
        @include flex-center;
        width: rem(32);
        height: rem(24);
        border-radius: rem(8);
        background-color: $close-btn-color;
        color: $sidebar-background-color;
      }
    }
  }

  .msg-wrapper {
    @include fm(12);
    color: $user-heading-tab-txt;
    position: relative;
    top: 30px;
    left: 0;
    z-index: 1;
    top: rem(-15);

  }

  .item-pill-button-wrapper {
    .list-items-wrapper {
      display: inline-block;
      position: relative;

      .confirmation-wrapper {
        position: absolute;
        top: rem(30);
        z-index: 1;

        &::after {
          content: "";
          position: absolute;
          top: rem(-11);
          left: rem(50);
          margin-left: rem(-5);
          border-width: rem(6);
          border-style: solid;
          border-color: transparent transparent white transparent;
        }
      }
    }

    .axiom-pill-btn-container .pill-wrapper .text {
      @include fm(15);
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .md {
      width: rem(210);
      height: rem(52);
      margin: 0;
      padding: 0 rem(24);
    }
    .empty-list .profile-icon {
      border: dashed rem(2) $cancel-button-color;
      border-radius: 50%;
    }
  }

  .empty-list {
    .md {
      border: dashed rem(2) $close-btn-color;
      height: rem(52);
    }
  }
}

.tooltipContent.displace.Add-to-add-tester {
  max-width: rem(200);
  width: max-content;
  white-space: inherit;
  text-align: left;

  &.center-text {
    text-align: center;
  }
}