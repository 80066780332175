@import "~assets/styles/common";

.axiom-typecounter-container {
  margin-right: rem(48);

  .axiom-typecounter-wrapper {
    @include flex-center;
    color: $close-btn-color;
    cursor: pointer;

    .type {
      @include fsb(22);
      margin-right: $rem12;
      position: relative;
    }

    .count {
      @include fsb(15);
      @include flex-center;
      width: rem(36);
      height: rem(28);
      border-radius: $rem10;
      background-color: $close-btn-color;
      color: $sidebar-background-color;

      &.hide-count {
        visibility: hidden;
      }
    }
  }

  .axiom-typecounter-wrapper.active {
    color: $axiom-primary-color;

    .type::after {
      content: "";
      position: absolute;
      width: rem(28);
      height: $rem3;
      background-color: $axiom-primary-color;
      border-radius: $rem5;
      bottom: (-$rem5);
      left: rem(0);
    }

    .count {
      background-color: $axiom-primary-color;
    }
  }
}
