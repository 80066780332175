$signin-heading-color: #151426;
$signin-text-color: #2e384a;
$white-color: #ffffff;
$black-color: #000000;
$axiom-primary-color: #2272fe;
$axiom-primary-op-7: rgba(34, 114, 254, 0.7);
$sidebar-background-color: #ffffff;
$axiom-body-background-color: #eff2f6;
$axiom-body-background-op-3: rgba(239, 242, 246, 0.3);
$dark-overlay: rgba(21, 20, 38, 0.25);
$confirmation-text-color: #353029;
$cancel-button-color: #a2b2c8;
$confirmation-box-shadow: rgba(0, 0, 0, 0.11);
$sidebar-background-color: #ffffff;
$user-heading-tab: #e4ebf4;
$user-heading-tab-light: rgba(228, 235, 244, 0.3);
$user-heading-tab-txt: #151426;
$user-list-text: #2e384a;
$light-grey-op2: rgba(162, 178, 200, 0.2);
$transparent: #ffffff00;
$static-header-background: rgba(239, 242, 246, 0.8);
$axiom-input-background-color: #c3d1e5;
$axiom-input-label-color: #a2b2c8;
$primary-text-color: #151426;
$primary-text-op-4: rgba(21, 20, 38, 0.4);
$close-btn-color: #a2b2c8;
$alert-panel-box-shadow: rgba(0, 0, 0, 0.09);
$user-unchecked-border: rgba(162, 178, 200, 0.6);
$user-list-hover-shadow: rgba(0, 0, 0, 0.1);
$user-checked-green: #24ba84;
$filter-dropdown-shadow: rgba(0, 0, 0, 0.08);
$filter-border-color: rgba(162, 178, 200, 0.3);
$search-border: #c3d1e5;
$list-container-box-shadow: rgba(0, 0, 0, 0.11);
$input-text-color: #a2b2c8;
$external-users-indicator: #fd8925;
$input-error-indicator: #f5332f;
$invitation-pending-color: #ffc100;
$user-name-group-light: rgba(162, 178, 200, 0.3);
$border-color: #ecf0f4;
$image-background: #f3f6fa;
$share-with-border-color: #e2eaf4;
$project-detail-overlay-shadow: rgba(0, 0, 0, 0.0584572);
$project-detail-background-overlay: rgba(255, 255, 255, 0.9);
$inner-box-color: #f7f9fc;
$solitude: #e4ebf4;
$red: #ff0000;
$black-op4: rgba(0, 0, 0, 0.4);
$white-op8: rgba(255, 255, 255, 0.8);
$mobile-border-separator: rgba(60, 60, 67, 0.36);
$system-blue: #007aff;
$light-red: #f15448;
$eerie-black: #1b1b1b;
$light-grey-op1: rgba(128, 128, 128, 0.1);
