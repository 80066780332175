@import "~assets/styles/common";

.m-change-password-wrapper {
  @extend .mobile-wrapper;
  @include flex-column;
  padding-bottom: rem(59);
  height: $viewport-height !important;

  .change-password-text {
    @include fsb(28);
    margin-bottom: rem(44);
  }

  .password-error {
    margin-bottom: 0;
  }

  .m-input-password-wrapper {
    margin-bottom: rem(16);
  }

  .button {
    margin-top: auto;
    text-align: center;

    .save-button {
      @include fsb(16);
      width: 100%;
      height: rem(52);
    }
  }
}
