@import "~assets/styles/common";

.axiom-list-container {
  @include fm(14);

  width: 100%;
  height: 100%;
  color: $signin-heading-color;
  border-radius: rem(1.8);
  padding: rem(16) rem(16) rem(16) rem(20);
  background-color: $sidebar-background-color;
  box-shadow: 0 $rem7 rem(26) 0 $list-container-box-shadow;
  overflow: auto;
  position: absolute;
  z-index: 1;
  height: auto;

  .list-wrapper {
    list-style: none;

    .list-item {
      @include flex(center, flex-start);
      padding: $rem8 0;
      cursor: pointer;

      .icon-wrapper {
        @include flex-center;
        width: rem(16);
        height: rem(16);
        border-radius: $rem4;
        border: rem(2) solid $axiom-input-label-color;
        margin-right: rem(12);
        pointer-events: none;

        .inner-box {
          display: inline-block;
          width: $rem10;
          height: $rem10;
          border-radius: $rem2;
          background-color: $transparent;
        }
      }

      &.active {
        .icon-wrapper {
          border-color: $axiom-primary-color;

          .inner-box {
            background-color: $axiom-primary-color;
          }
        }
      }

      &:hover {
        color: $axiom-primary-color;
      }
    }

    .selected-item {
      @include flex-center;
      position: absolute;
      right: rem(16);

      svg {
        width: rem(18);
        height: rem(18);

        rect {
          fill: $user-checked-green;
        }

        path {
          fill: $sidebar-background-color;
        }
      }
    }

    .empty-item {
      @include flex;
      padding: $rem8 0;
    }
  }

  &.search-list {
    border-radius: $rem8;
    max-height: rem(176);
    top: calc(100% + #{$rem8});
  }

  &.dropdown-list {
    width: rem(180);
    right: 0;
    top: rem(53);
    border-radius: rem(1.8);
    box-shadow: 0 rem(7) rem(26) 0 $confirmation-box-shadow;
    max-height: rem(182);
  }

  &.with-create-folder {
    padding: 0;
    max-height: rem(300);

    .folder-box {
      height: rem(72);
      padding: 0 rem(24);
      border: $rem2 solid $border-color;

      &:hover {
        box-shadow: $rem5 0 rem(17) 0 $filter-dropdown-shadow;
      }
    }

    .create-folder-input-wrapper {
      @include flex(center, space-between);

      & > div:first-child {
        flex: 1;
      }

      .folder-input {
        width: auto;
        height: auto;
        border: none;
        border-radius: 0;

        input {
          padding: 0 rem(16) 0 0;
          border-radius: 0;
          border: none;

          &:focus {
            caret-color: $axiom-primary-color;
          }
        }
      }

      .button:nth-child(2) {
        margin: 0 rem(24) 0 auto;
      }

      .cancel-btn,
      .save-btn {
        @include fsb(14);
        width: auto;
        height: auto;
        background-color: $white-color;
        border: none;
      }

      .cancel-btn {
        color: $cancel-button-color;
      }

      .save-btn {
        color: $axiom-primary-color;
      }

      &.is-focussed {
        border: $rem2 solid $axiom-primary-color;
      }
    }

    .create-folder {
      @include flex(center, space-between);
      border-bottom: none;

      .name {
        @include fsb(16);
        color: $axiom-primary-color;
      }

      .plus-btn {
        @include flex-center;
        cursor: pointer;
        border: none;
        background-color: $white-color;

        svg {
          width: rem(40);
          height: rem(40);

          & > g {
            stroke: $axiom-primary-color;
          }
        }
      }

      &.add-bottom-border {
        border-bottom: $rem2 solid $border-color;
      }
    }
  }

  &.no-border {
    border-radius: 0;
  }

  &.global-search {
    .list-wrapper {
      .list-item {
        @include flex(center, space-between);

        .additional-info {
          @include flex-center;
          @include fsb(14);
          position: relative;
          color: $axiom-primary-color;
          text-transform: capitalize;

          &::before {
            content: "";
            position: absolute;
            display: inline-block;
            width: rem(10);
            height: rem(10);
            top: 50%;
            left: #{-$rem15};
            background-color: $axiom-primary-color;
            border-radius: 50%;
            transform: translateY(-50%);
          }

          &.deleted,
          &.blocked {
            color: $input-error-indicator;
            &::before {
              background-color: $input-error-indicator;
            }
          }
        }
      }

      .title {
        @include fsb(13);
        color: $cancel-button-color;
        margin: rem(24) 0 $rem8;

        &:first-child {
          margin-top: rem(16);
        }
      }
    }
  }
}
