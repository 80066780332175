@import "~assets/styles/common";

.axiom-alert-panel-container {
  @include flex-center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: rem(104);
  border-radius: rem(16);
  box-shadow: 0 (-$rem6) rem(24) 0 $alert-panel-box-shadow;
  background-color: $sidebar-background-color;
  z-index: 3;
  transform: translateY(110%);
  animation: moveUp 0.1s ease-out 0s 1 forwards;
}

@keyframes moveUp {
  100% {
    transform: translateY(0);
  }
}
