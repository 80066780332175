@import "~assets/styles/common";

.axiom-build-wrapper {
  background-color: $white-color;
  cursor: pointer;

  .individual-build {
    @include flex(center, space-between);
    height: 100%;
    position: relative;

    .name,
    .date {
      @include fm(14);
      color: $user-list-text;
    }

    .name {
      @include truncate-text;
      padding-right: $rem7;
      width: 40%;
      max-width: rem(156);
    }

    .date {
      flex: rem(105) 0 0;
      padding-right: $rem7;
    }

    .download-link svg,
    .more-options svg {
      width: rem(32);
      height: rem(32);
    }

    .state {
      position: static;
    }

    .download-link {
      margin: 0 rem(20) 0 auto;
      cursor: pointer;
    }

    .more-options {
      .name-more-icon {
        cursor: pointer;
      }

      .build-popup-wrapper {
        .build-popup {
          right: rem(-10);
        }

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          border: $rem4 solid $transparent;
          border-bottom: $rem4 solid $white-color;
          z-index: 2;
          bottom: rem(17);
          right: rem(12);
        }
      }

      .delete-confirmation {
        right: rem(-40);

        &::after {
          right: rem(50);
        }
      }

      &.active {
        svg g {
          fill: $primary-text-color;
        }
      }
    }
  }

  &:last-child {
    .more-options {
      .build-popup-wrapper {
        &::after {
          bottom: rem(15);
        }
      }
    }
  }

  &.folder-box {
    height: rem(72);
    padding: 0 rem(15) 0 rem(24);
    border: $rem2 solid $border-color;
    border-bottom: none;

    &:last-child {
      border-bottom: $rem2 solid $border-color;
    }
  }

  &.active {
    border-color: $user-checked-green;

    & + .folder-box {
      border-top-color: $user-checked-green;
    }
  }

  &.folder-box.active {
    &:last-child {
      border-color: $user-checked-green;
    }
  }
}
