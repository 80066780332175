/// Remove the unit of a length
/// @param {Number} $value - Number to remove unit from
/// @return {Number} Unitless number
/// @link https://github.com/sass/sass/issues/533#issuecomment-11675408
/// @author Rob Wierzbowski
/// @example
///   $length: 42px;
///   $value: strip-units($length);
///   // -> 42
@function strip-units($value) {
    @if (type-of($value) == 'null') {
      @return 0;
    } @else {
      @return $value / ($value * 0 + 1);
    }
  }
  
  /// Return if value is numbrable after removing unit
  /// @param {Number|String} $value - Number to remove unit from
  /// @return {Boolean}
  /// @example
  ///   $value: is-unitless(10px);
  ///   // -> true
  /// @example
  ///   $value: is-unitless(10);
  ///   // -> true
  /// @example
  ///   $value: is-unitless(hello);
  ///   // -> false
  @function is-unitless($value) {
    @return type-of(strip-units($value)) == 'number';
  }
  
  /// Return rem from px
  /// @param {Number|String} $value - px
  /// @return {String}
  /// @example
  ///   $value: rem(10px);
  ///   // -> 0.625rem
  /// @example
  ///   $value: rem(10);
  ///   // -> 0.625rem
  /// @example
  ///   $value: rem(hello);
  ///   // -> 0
  @function rem($value) {
    $num: strip-units($value);
    $rnum: strip-units($rem);
    @return #{$num / $rnum}rem;
  }