@import "~assets/styles/common";

.axiom-sidepanel-container {
  position: fixed;
  top: 0;
  width: rem(580);
  height: $viewport-height;
  overflow: auto;
  z-index: 2;
  background-color: $sidebar-background-color;
  transition: transform 0.5s;
  transform: translateX(0);
  &::-webkit-scrollbar {
    display: none;
  }

  // sp prefix is added to avoid css classes of right, left of Tooltip
  &.sp-right {
    right: 0;
    transform: translateX(150%);
  }

  &.sp-left {
    left: 0;
    transform: translateX(-150%);
  }

  &.sp-left-offset {
    left: rem(104);
    transform: translateX(-150%);
  }

  &.right-full {
    right: 0;
    transform: translateX(150%);
  }

  &.move-in {
    transform: translateX(0);
  }

  &.user-details {
    width: rem(838);
  }

  &.group-details {
    width: rem(596);
  }

  &.project-details {
    min-width: rem(560);
    width: auto;
    @include flex;
  }

  &.upload-build {
    width: rem(776);
  }

  &.add-user {
    width: $viewport-width;
  }
}
