// Load fonts
@font-face {
  font-family: Poppins-SemiBold;
  src: url("../../fonts/Poppins-SemiBold.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins-Regular;
  src: url("../../fonts/Poppins-Regular.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins-Medium;
  src: url("../../fonts/Poppins-Medium.ttf");
  font-display: swap;
}
@font-face {
  font-family: Poppins-Bold;
  src: url("../../fonts/Poppins-Bold.ttf");
  font-display: swap;
}

/// Mixin to set font size, line height, and letter spacing.
/// @group 004 - font
/// @param {Number} $size [null] - font size
/// @param {Number} $line-height [null] - line height
/// @param {Number} $small-size [null] - font size for mobile
/// @param {Number} $small-line-height [null] - line height for mobile
/// @param {Boolean} $is-scaled [false] - condition to be scalable by browser size (`x-small` and `medium-only`)
/// @example
///   @include f(16, 20, 14, 22);
///   // font-size: 1rem;
///   // line-height: 1.25;
///   // @media (max-width: 47.9375em) {
///   //   font-size: 0.875rem;
///   //   line-height: 1.57;
///   // }
@mixin f(
  $size: null,
  $line-height: null,
  $small-size: null,
  $small-line-height: null,
  $is-scaled: false
) {
  @if (type-of($size) == "number") {
    font-size: rem($size);
  }
  @if (type-of($size) == "number" and type-of($line-height) == "number") {
    line-height: $line-height / $size;
  }
  @if (type-of($small-size) == "number") {
    @include small {
      font-size: rem($small-size);
    }
  }
  @if (
    type-of($small-size) == "number" and type-of($small-line-height) == "number"
  ) {
    @include small {
      line-height: $small-line-height / $small-size;
    }
  }
  @if (type-of($size) == "number" and $is-scaled == true) {
    @include medium-only {
      font-size: (($size * 1px) / $mdpi-width) * 100vw;
    }
  }
  @if (type-of($small-size) == "number" and $is-scaled == true) {
    @include x-small {
      font-size: (($small-size * 1px) / $small-mobile-width) * 100vw;
    }
  }
}

/// Mixin to set **Poppins Regular** and font size, line height, and letter spacing as option.
/// @group 004 - font
/// @param {Number} $size [null] - font size
/// @param {Number} $line-height [null] - line height
/// @param {Number} $small-size [null] - font size for mobile
/// @param {Number} $small-line-height [null] - line height for mobile
/// @param {Boolean} $is-scaled [false] - condition to be scalable by browser size (`x-small` and `medium-only`)
/// @example
///   @include fr;
///   // font-family: Poppins-Regular, sans-serif;
///   // font-weight: 400;
///   // font-style: normal;
/// @example
///   @include fr(16, 20, 14, 22);
///   // font-family: Poppins-Regular, sans-serif;
///   // font-weight: 400;
///   // font-style: normal;
///   // font-size: 1rem;
///   // line-height: 1.25;
///   // @media (max-width: 47.9375em) {
///   //   font-size: 0.875rem;
///   //   line-height: 1.57;
///   // }
@mixin fr(
  $size: null,
  $line-height: null,
  $small-size: null,
  $small-line-height: null,
  $is-scaled: false
) {
  @include f($size, $line-height, $small-size, $small-line-height, $is-scaled);
  font-family: Poppins-Regular, sans-serif;
  font-weight: 400;
  font-style: normal;
}

/// Mixin to set **Poppins Medium** and font size, line height, and letter spacing as option.
/// @group 004 - font
/// @param {Number} $size [null] - font size
/// @param {Number} $line-height [null] - line height
/// @param {Number} $small-size [null] - font size for mobile
/// @param {Number} $small-line-height [null] - line height for mobile
/// @param {Boolean} $is-scaled [false] - condition to be scalable by browser size (`x-small` and `medium-only`)
/// @example
///   @include fm;
///   // font-family: Poppins-Medium, sans-serif;
///   // font-weight: 500;
///   // font-style: normal;
/// @example
///   @include fm(16, 20, 14, 22);
///   // font-family: Poppins-Medium, sans-serif;
///   // font-weight: 500;
///   // font-style: normal;
///   // font-size: 1rem;
///   // line-height: 1.25;
///   // @media (max-width: 47.9375em) {
///   //   font-size: 0.875rem;
///   //   line-height: 1.57;
///   // }
@mixin fm(
  $size: null,
  $line-height: null,
  $small-size: null,
  $small-line-height: null,
  $is-scaled: false
) {
  @include f($size, $line-height, $small-size, $small-line-height, $is-scaled);
  font-family: Poppins-Medium, sans-serif;
  font-weight: 500;
  font-style: normal;
}

/// Mixin to set **Poppins Bold** and font size, line height, and letter spacing as option.
/// @group 004 - font
/// @param {Number} $size [null] - font size
/// @param {Number} $line-height [null] - line height
/// @param {Number} $small-size [null] - font size for mobile
/// @param {Number} $small-line-height [null] - line height for mobile
/// @param {Boolean} $is-scaled [false] - condition to be scalable by browser size (`x-small` and `medium-only`)
/// @example
///   @include fb;
///   // font-family: Poppins-Bold, sans-serif;
///   // font-weight: 600;
///   // font-style: normal;
/// @example
///   @include fb(16, 20, 14, 22);
///   // font-family: Poppins-Bold, sans-serif;
///   // font-weight: 600;
///   // font-style: normal;
///   // font-size: 1rem;
///   // line-height: 1.25;
///   // @media (max-width: 47.9375em) {
///   //   font-size: 0.875rem;
///   //   line-height: 1.57;
///   // }
@mixin fb(
  $size: null,
  $line-height: null,
  $small-size: null,
  $small-line-height: null,
  $is-scaled: false
) {
  @include f($size, $line-height, $small-size, $small-line-height, $is-scaled);
  font-family: Poppins-Bold, sans-serif;
  font-weight: 600;
  font-style: normal;
}

/// Mixin to set **Poppins SemiBold** and font size, line height, and letter spacing as option.
/// @group 004 - font
/// @param {Number} $size [null] - font size
/// @param {Number} $line-height [null] - line height
/// @param {Number} $small-size [null] - font size for mobile
/// @param {Number} $small-line-height [null] - line height for mobile
/// @param {Boolean} $is-scaled [false] - condition to be scalable by browser size (`x-small` and `medium-only`)
/// @example
///   @include fsb;
///   // font-family: Poppins-SemiBold, sans-serif;
///   // font-weight: 600;
///   // font-style: normal;
/// @example
///   @include fsb(16, 20, 14, 22);
///   // font-family: Poppins-SemiBold, sans-serif;
///   // font-weight: 600;
///   // font-style: normal;
///   // font-size: 1rem;
///   // line-height: 1.25;
///   // @media (max-width: 47.9375em) {
///   //   font-size: 0.875rem;
///   //   line-height: 1.57;
///   // }
@mixin fsb(
  $size: null,
  $line-height: null,
  $small-size: null,
  $small-line-height: null,
  $is-scaled: false
) {
  @include f($size, $line-height, $small-size, $small-line-height, $is-scaled);
  font-family: Poppins-SemiBold, sans-serif;
  font-weight: 600;
  font-style: normal;
}
