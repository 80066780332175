@import "~assets/styles/common";

.axiom-textarea-label {
  @include fm(12);
  display: inline-block;
  color: $axiom-input-label-color;
  margin-top: rem(32);
  margin-bottom: rem(12);
}

.axiom-textarea-field {
  position: relative;
  textarea {
    @include fm(14);
    white-space: wrap;
    color: $signin-heading-color;
    width: rem(420);
    min-height: rem(106);
    padding: rem(16);
    border-radius: rem(8);
    resize: none;
    border: solid rem(1) $axiom-input-background-color;
  }
  .text-area-count {
    @include fm(12);
    position: absolute;
    top: 100%;
    right: 0;
    color: $cancel-button-color;
  }
  textarea:focus {
    outline: none;
    border: rem(1) solid $axiom-primary-color;
  }
}
