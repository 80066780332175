@import "~assets/styles/common";

.m-search-container {
  @extend .max-mobile-width;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: $viewport-width;
  height: $viewport-height;
  z-index: 2;
  background-color: $white-color;
  padding: rem(28) rem(24) rem(20);

  // loader style
  .axiom-loader {
    @include flex-center;
    margin-top: rem(24);
  }

  .axiom-list-container {
    position: static;
    box-shadow: none;
    max-height: none;

    .list-wrapper {
      li {
        @include fm(15);
      }
    }
  }

  .no-result-text {
    @include fm(20);
    padding-top: $rem15;
  }
}
