@import "~assets/styles/common";

.axiom-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: rem(104);
  z-index: 1;
  background-color: $sidebar-background-color;

  &.liftUp {
    box-shadow: 0 rem(11) rem(19) 0 $filter-dropdown-shadow;
    animation: lift-up 1s linear 0s forwards;
  }

  // show the tooltip away from the border to prevent clipping
  .tooltipContent {
    left: 60%;

    &::after {
      left: calc(50% - #{rem(14)});
    }
  }

  @keyframes lift-up {
    0% {
      z-index: 1;
    }

    100% {
      z-index: 3;
    }
  }

  .axiom-nav-link {
    display: inline-block;
    padding: 0 rem(33) 0 rem(36);
    text-decoration: none;
    border-right: rem(3) solid $transparent;
    cursor: pointer;

    svg {
      width: rem(32);
      height: rem(32);
      fill: $input-text-color;
    }
  }

  ul {
    list-style-type: none;

    &.flex {
      @include flex-center;
      height: inherit;
      flex-direction: column;

      .tooltipContent {
        left: 55%;
      }

      li:not(:last-child) {
        margin-bottom: rem(32);
      }

      li {
        width: 100%;
        border-left: rem(3) solid $transparent;
        border-right: rem(3) solid $transparent;

        .name-ci_cd-icon {
          fill: $close-btn-color;
        }

        .button {
          @include flex-center;

          button {
            border: none;
            background-color: $white-color;
            cursor: pointer;

            .flex-container {
              @include flex-center;
            }
          }
        }

        &.active {
          border-right: rem(3) solid $axiom-primary-color;

          .name-build-icon {
            svg g g {
              stroke: $axiom-primary-color;

              path {
                fill: $axiom-primary-color;
              }
            }
          }

          .name-tester-icon {
            svg g g {
              fill: $axiom-primary-color;
            }
          }

          .name-groups-icon {
            svg g g {
              stroke: $axiom-primary-color;
            }
          }

          .name-ci_cd-icon {
            svg {
              fill: $axiom-primary-color;
            }
          }

          .name-settings {
            svg path{
              stroke: $axiom-primary-color;
            }
          }
        }
      }
    }
  }
  img {
    height: inherit;
    width: inherit;
    vertical-align: middle;
    border-style: none;
  }
  .active {
    border-right: rem(3) solid $axiom-primary-color;

    .axiom-icon {
      filter: invert(0.8) sepia(1) saturate(5) hue-rotate(175deg);

      &.tick,
      &.name-feedbacks {
        filter: none;

        svg {
          fill: $axiom-primary-color;
        }
      }
    }
  }
}

.axiom-logo {
  position: absolute;
  top: rem(64);
  left: 50%;
  height: rem(56);
  width: rem(56);
  transform: translateX(-50%);
  text-align: center;
  img {
    width: rem(55);
    height: 100%;
  }
}
.logout-menu {
  bottom: rem(60);
}
.projects-menu {
  top: 45%;
}
.users-menu {
  top: 50%;
}
.feedbacks {
  bottom: rem(240);
}
.notification-menu {
  bottom: rem(180);
}
.profile-menu {
  bottom: rem(120);
  cursor: pointer;
}
.logout-menu,
.projects-menu,
.users-menu,
.feedback-menu,
.feedbacks,
.notification-menu,
.profile-menu {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.logout-confirmation {
  position: absolute;
  bottom: rem(10);
  left: rem(120);
}
