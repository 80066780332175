@import "~assets/styles/common";

.m-forgot-password-container {
  padding: rem(64) rem(32) 0;
  height: $viewport-height;

  form {
    @include flex-column;
    height: 100%;

    .heading {
      @include fsb(28);
      color: $user-heading-tab-txt;
      margin: rem(52) 0 rem(24);
    }

    .text {
      @include fm(14);
      color: $input-text-color;
      margin-bottom: rem(56);
    }

    .internal-user-err {
      @extend .input-error;
      margin: $rem8 0 $rem12;
    }

    .btn-wrapper {
      margin: auto 0 rem(60);

      .send-btn {
        height: rem(52);
        width: 100%;
      }
    }

    .img-email-sent {
      width: rem(152);
      height: rem(138);
      margin-top: rem(133);
      align-self: center;
    }

    .resend-wrapper {
      @include flex-center;
      margin: auto auto rem(91);

      .resend-text {
        @include fm(12);
        color: $axiom-input-label-color;
        margin-right: $rem5;
      }

      .resend-btn {
        @include fsb(12);
        color: $axiom-primary-color;
        width: auto;
        height: auto;
        background-color: $transparent;
        border: none;
      }
    }
  }
}
