$max-mobile-width: rem(720);

.max-mobile-width {
  max-width: $max-mobile-width;
}

.mobile {
  @extend .max-mobile-width;
  margin: 0 auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;

  // have width auto and change placeholder color for input
  .axiom-input-field {
    width: auto;

    input::placeholder {
      color: $axiom-input-label-color;
    }
  }

  input:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .observer {
    @include fsb(12);
    width: inherit;
    min-height: $rem2;
    text-align: center;
    padding: $rem10 0;
  }
}

.mobile-wrapper {
  padding: rem(113) rem(24) rem(24);
  // 96px is the height of the bottom nav bar
  height: calc(#{$viewport-height} - #{rem(96)});
  overflow: auto;
  margin: 0 auto;
}

// used to show content at the center of the mobile screen (in case of error or empty list)
.m-center-content {
  @include fsb(22);
  @include flex-center;
  color: $signin-heading-color;
  width: $viewport-width;
  height: $viewport-height;
  padding: 0 rem(24);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
}

// overlay with confirmation popup for mobile
.m-overlay {
  @include flex-center;
  width: $viewport-width;
  height: $viewport-height;
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: $black-op4;
  z-index: 3;

  .axiom-confirmation-popup {
    height: auto;

    .confirmation-box {
      background-color: $white-op8;
      backdrop-filter: blur(54.3656px);
      padding: 0;

      .confirm-text {
        text-align: center;
        padding: rem(19) 0;
        margin: 0;
        border-bottom: $rem1 solid $mobile-border-separator;
      }

      .main-text {
        @include fsb(17);
      }

      .sub-text {
        @include fr(13);
      }
    }

    .button-confirmation {
      .button,
      button {
        width: 100%;
      }

      button {
        @include fr(17);
        height: rem(43);
        border: none;
        margin: 0;
        border-radius: 0;
        background-color: $transparent;
        color: $system-blue;
      }

      .button:first-child {
        border-right: $rem1 solid $mobile-border-separator;
      }
    }
  }
}

// input error (style for the error shown below the input)
.input-error {
  @include fm(10);
  display: block;
  color: $input-error-indicator;
}

// change the alert panel to show only the icon and the message for mobile
@media screen and (max-width: 768px) {
  .axiom-alert-panel-container {
    justify-content: space-between;
    padding: 0 $rem15;

    .msg-text {
      margin-right: 0;
    }

    .close-btn {
      display: none;
    }
  }
}
