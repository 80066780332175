@import "~assets/styles/common";

.text {
  @include fsb(14);
  color: $confirmation-text-color;
}

.axiom-confirmation-popup {
  width: rem(330);
  height: rem(148);

  &.displace {
    position: absolute;
    z-index: 2;
  }

  &.danger-popup {
    .text {
      color: $input-error-indicator;

      &::before {
        content: "\26A0";
        position: relative;
        display: inline-block;
        margin-right: $rem5;
        color: $input-error-indicator;
      }
    }

    .popup-accept-button {
      background-color: $input-error-indicator;
      border: none;
    }

    .popup-cancel-button {
      color: $input-error-indicator;
      border-color: $light-red;
    }
  }
}

.hidden {
  display: none;
}

.confirmation-box {
  width: rem(320);
  height: auto;
  object-fit: contain;
  border-radius: rem(10);
  box-shadow: 0 rem(7) rem(26) 0 $confirmation-box-shadow;
  padding: rem(32) rem(24);
  background-color: $white-color;
}

.confirm-text {
  margin-bottom: rem(27);
}

.button-confirmation {
  display: flex;
}
