@import "~assets/styles/common";

.m-back-button-wrapper {
  .button {
    @include flex;
    width: max-content;
    
    button {
      width: auto;
      height: auto;
      background-color: $transparent;
      border: none;

      .left-arrow {
        @include flex;
      }
    }

    .eyes {
      @include flex;
    }
  }
}