@import "~assets/styles/common";

.axiom-search {
  .input-wrapper {
    position: relative;

    input {
      @include fm(15);
      background-color: $sidebar-background-color;
      border-radius: 8px;
      width: 100%;
      padding: rem(15) rem(44) rem(16) rem(50);
      color: $signin-heading-color;
      border: none;
      outline: none;
      box-sizing: border-box;
      caret-color: $axiom-primary-color;

      &::placeholder {
        color: $input-text-color;
      }

      &:focus {
        border-color: $axiom-primary-color;
      }

      &:focus::placeholder {
        color: $axiom-primary-color;
      }
    }

    .search-icon,
    .cancel-btn {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .search-icon {
      left: rem(18);

      .name-search-icon {
        @include flex;
      }

      svg {
        width: rem(18);
        height: rem(19);

        path:first-child {
          fill: $input-text-color;
        }
        path:nth-child(2) {
          stroke: $input-text-color;
        }
      }

      &.focussed {
        svg {
          path:first-child {
            fill: $axiom-primary-color;
          }

          path:nth-child(2) {
            stroke: $axiom-primary-color;
          }
        }
      }
    }

    .clear-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem(16);
      cursor: pointer;

      .name-clear-icon {
        @include flex;
      }

      svg {
        width: rem(20);
        height: rem(20);

        circle {
          fill: $axiom-body-background-color;
        }
        g {
          fill: $signin-heading-color;
        }
      }
    }
  }

  &.no-border {
    .axiom-input-field {
      border: none;
    }
  }

  &.has-border {
    input {
      border: $rem1 solid $search-border;
    }

    .axiom-input-field {
      border: none;
      width: inherit;
      border-radius: 0;
    }
  }

  &.with-cancel-btn {

    .search-icon {
      left: $rem2;

      &.focussed {
        svg {
          path:first-child {
            fill: $cancel-button-color;
          }

          path:nth-child(2) {
            stroke: $cancel-button-color;
          }
        }
      }
    }

    input {
      padding: rem(18) rem(90) rem(18) rem(32);
      border-radius: 0;
      border-bottom: $rem1 solid $user-heading-tab;
      
      &:focus {
        border-color: $user-heading-tab;
      }

      &::placeholder,
      &:focus::placeholder {
        color: $cancel-button-color;
      }
    }

    .clear-icon {
      right: rem(60);
    }

    .cancel-btn {
      right: rem(2);

      button {
        @include fm(13);
        color: $user-heading-tab-txt;
        width: auto;
        height: auto;
        background-color: $transparent;
        border: none;
      }
    }
  }
}
.search-bar-wrapper {
  width: rem(604);
  border: none;
}
