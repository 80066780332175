@import "~assets/styles/common";

.axiom-static-header-container {
  .header-wrapper {
    width: 100%;
  }

  .row-1-wrapper,
  .row-2-wrapper {
    @include flex-center;
    & > div:last-child {
      margin-left: auto;
    }
  }

  .row-1-wrapper {
    margin-bottom: rem(62);
  }

  .row-2-wrapper {
    .col-1 {
      @include flex-center;
    }
  }

  .search-bar {
    margin-right: rem(24);
  }
}

.indicator-container {
  @include flex-row;
}

.internal-indicator {
  margin-top: rem(4);
  width: rem(16);
  height: rem(16);
  border-radius: 50%;
  background-color: $axiom-primary-color;
}

.indicator-text {
  @include fm(16);
  color: $primary-text-color;
  padding-left: rem(12);
}

.external-indicator {
  margin: rem(4) 0 0 rem(47);
  width: rem(16);
  height: rem(16);
  border-radius: 50%;
  background-color: $external-users-indicator;
}
