@import "~assets/styles/common";

.axiom-project-wrapper {
  width: rem(328);
  height: rem(298);
  border-radius: rem(16);
  padding: rem(32);
  background-color: $white-color;
  margin: 0 rem(24) 0 0;
  position: relative;
  cursor: pointer;

  .dull {
    opacity: 0.3;
  }

  .image-more-options-wrapper {
    @include flex(flex-start, space-between);

    .project-icon-wrapper {
      height: rem(56);
      width: rem(56);
      margin-bottom: rem(48);
      border-radius: rem(8);

      img {
        height: inherit;
        width: inherit;
        border-radius: inherit;
      }
    }

    .more-options {
      @include flex-center;
      width: rem(32);
      height: rem(32);
      flex: 0 0 rem(32);
      background-color: $axiom-body-background-color;
      border-radius: $rem8;
      position: relative;
      cursor: pointer;

      &.light {
        background-color: $axiom-body-background-op-3;
      }

      .middle-dot {
        width: $rem4;
        height: $rem4;
        background-color: $close-btn-color;
        position: relative;
        border-radius: 50%;

        &.dots-active {
          background-color: $signin-heading-color;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: inherit;
          height: inherit;
          background-color: inherit;
          border-radius: inherit;
        }

        &::before {
          top: 200%;
        }

        &::after {
          bottom: 200%;
        }
      }

      .group-container {
        position: absolute;
        top: calc(100% + #{$rem8});
        right: rem(-12);

        .group-popup {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: $rem7;
          width: rem(141);
          max-height: none;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: rem(-8);
        }

        &::before {
          width: 0;
          height: 0;
          right: rem(24);
          border: $rem4 solid transparent;
          border-bottom: $rem4 solid $white-color;
          z-index: 2;
        }

        // added so that the popup doesn't disappear when moving out of the region (added to increase the region of hovering)
        &::after {
          width: rem(32);
          height: rem(10);
          right: $rem12;
          z-index: 1;
        }
      }

      .delete-confirmation {
        top: calc(100% + #{$rem10});
        right: rem(-40);
        // to display the upward arrow above the Confirmation popup
        z-index: 3;

        &::after {
          right: rem(50);
        }
      }
    }

    .axiom-confirmation-popup {
      .confirmation-box {
        width: max-content;

        .popup-accept-button {
          width: auto;
          padding: 0 rem(20);
        }
      }
    }
  }

  .project-name-title {
    @include fsb(22);
    color: $user-heading-tab-txt;
    margin-bottom: rem(8);
  }

  .project-description {
    @include fr(13);
    color: $user-list-text;
    height: rem(43);
    margin-bottom: rem(25);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .name-android-icon,
  .name-ios-icon,
  .name-web-icon {
    height: rem(20);
    width: rem(20);
    margin-right: rem(20);
  }

  .project-platforms-wrapper {
    @include flex(flex-start, flex-start);
  }
}

.state {
  @include flex-center;
  @include fsb(14);
  position: absolute;
  bottom: rem(32);
  right: rem(32);
  color: $input-error-indicator;
  text-transform: capitalize;

  .circle {
    display: inline-block;
    width: rem(10);
    height: rem(10);
    background-color: $input-error-indicator;
    border-radius: 50%;
    margin-right: $rem6;
  }

  &.archived {
    color: $axiom-primary-color;
    .circle {
      background-color: $axiom-primary-color;
    }
  }
}
