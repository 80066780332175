@import "~assets/styles/common";

.axiom-share-with-container {
  &.for-groups {
    margin-top: rem(40);

    .share-with-wrapper {
      .title-wrapper {
        .types {
          margin-right: rem(269);
        }
      }
    }
  }

  .share-with-wrapper {
    .title-wrapper {
      @include flex(center, space-between);
      margin-bottom: $rem13;

      .title {
        @include fsb(14);
        color: $input-text-color;
      }

      .types {
        @include flex-center;

        .axiom-checkbox-container.filter-cb:not(:last-child) {
          margin-right: rem(24);
        }

        .axiom-checkbox-container.filter-cb:last-child {
          margin-right: $rem6;
        }
      }
    }

    .title-wrapper .types,
    .input-checkbox-wrapper {
      .axiom-checkbox-container.filter-cb {
        label {
          @include fm(12);
          color: $input-text-color;
        }

        &.highlight-label {
          label {
            color: $signin-heading-color;
          }
        }
      }
    }

    .input-all-checkbox-wrapper {
      overflow: hidden;
      border-radius: $rem8;
      border: $rem2 solid $share-with-border-color;

      .input-checkbox-wrapper {
        @include flex(center, space-between);
        height: rem(66);
        padding: 0 rem(20);
        border-bottom: $rem2 solid $share-with-border-color;

        .search-list-container {
          position: relative;

          .axiom-search {
            .axiom-input-field {
              width: rem(384);
              height: rem(26);

              input {
                @include fm(13);
                border-bottom: rem(1.5) solid $share-with-border-color;
                border-radius: 0;
                padding: 0;
                padding-left: rem(28);

                &:focus {
                  box-shadow: none;
                  border-bottom: rem(1.5) solid $axiom-primary-color;
                }
              }
            }

            .search-icon {
              left: $rem3;
              top: 45%;

              svg {
                width: rem(17);
                height: rem(17);
              }
            }

            .clear-icon {
              right: $rem3;

              svg {
                width: $rem14;
                height: $rem14;
              }
            }
          }
        }
      }

      .checkbox-container {
        height: rem(190);
        overflow: auto;
      }

      .all-checkbox-wrapper {
        display: flex;
        flex-wrap: wrap;
        padding: rem(20);
        overflow: auto;

        .individual-checkbox {
          flex-basis: 33%;
          margin-bottom: rem(16);

          svg {
            rect {
              fill: $user-checked-green;
            }

            path {
              fill: $sidebar-background-color;
            }
          }

          .container {
            @include flex(center, null);
            width: max-content;

            & > div {
              @include flex;
            }
          }
        }

        .axiom-checkbox-container.filter-cb {
          label {
            @include fr(12);
            color: $signin-text-color;
          }
        }
      }

      .heading-grp-members {
        @include fm(16);
        padding-left: rem(20);
        color: $primary-text-color;
      }

      /* check to see if browser supports css grid */

      @supports (display: grid) {
        .all-checkbox-wrapper {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: rem(16);

          .individual-checkbox {
            flex-basis: initial;
            margin-bottom: 0;

            &:last-child {
              margin-bottom: rem(20);
            }
          }
        }
      }
    }
  }
}
