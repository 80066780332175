@import "~assets/styles/common";

.axiom-add-to {
  .title-wrapper {
    @include flex(center, flex-start);
    color: $signin-heading-color;
    margin-bottom: rem(20);
    margin-top: rem(40);

    .title {
      @include fsb(18);
      margin-right: $rem8;
    }

    .optional {
      @include fm(18);
    }
  }

  .search-list-container {
    position: relative;
    margin-bottom: rem(12);
  }

  .selected-data-wrapper {
    @include flex-wrap;
  }

  .axiom-search {
    .axiom-input-field {
      width: auto;
    }
    
    .axiom-input-text {
      border: $rem1 solid $search-border;
    }
  }

  &.axiom-add-to.mb-32 {
    margin-bottom: rem(32);
  }

  &.axiom-add-to.mb-40 {
    margin-bottom: rem(40);
  }
}
