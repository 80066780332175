@import "~assets/styles/common";

.m-project-wrapper {
  @extend .mobile-wrapper;

  .heading-text {
    @include fsb(32);
    margin: 0 0 rem(28);
  }

  @supports (display: grid) {
    .m-common-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, rem(155));
      grid-gap: rem(16);

      &.list,
      &.no-flex {
        display: block;
      }
    }
  }
}