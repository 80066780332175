@import "~assets/styles/common";

.device-details-container {
  .title-count-wrapper {
    @include flex(center, flex-start);
    margin-bottom: rem(28);

    .device-title {
      @include fsb(20);
      color: $signin-heading-color;
      margin-right: rem(10);
      position: relative;
    }
    .device-count {
      @include fsb(14);
      @include flex-center;
      width: rem(32);
      height: rem(24);
      border-radius: rem(8);
      background-color: $close-btn-color;
      color: $sidebar-background-color;
    }
  }

  .device-details-heading-wrapper {
    background-color: $axiom-body-background-color;
    padding: rem(34) 0 rem(31) rem(48);
    border-top-left-radius: rem(8);
    border-top-right-radius: rem(8);

    .device-details-heading {
      @include flex;

      h4 {
        @include fsb(16);
        width: rem(140);
      }

      .model-label,
      .name-label {
        margin-right: rem(63);
      }
    }
  }
  .user-device-details-wrapper {
    border: solid rem(2) $border-color;
  }
}
