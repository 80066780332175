@import "~assets/styles/common";

.axiom-list-box {
  &.disabled {
    .axiom-user-role-value {
      cursor: not-allowed;
    }
  }

  &:focus {
    border: $rem1 solid $axiom-primary-color;
  }
}

.axiom-add-user-role-container {
  position: relative;
  width: rem(420);
  height: rem(52);
  border-radius: rem(8);
  border: solid 1px $axiom-input-background-color;

  .axiom-user-role-value {
    @include flex(center);
    @include fm(15);
    height: inherit;
    padding: rem(14) rem(16);
    color: $user-heading-tab-txt;
    cursor: pointer;
  }
  span {
    position: absolute;
    right: rem(16);
    width: rem(14);
    height: rem(8);

    .name-arrow-down,
    .name-arrow-up {
      @include flex;
    }
  }
}
