@import "~assets/styles/common";

.m-profile-wrapper {
  @extend .mobile-wrapper;

  .profile-text {
    @include fsb(32);
    margin-bottom: rem(36);
  }

  .sub-heading-text {
    @include fsb(16);
  }

  .profile-info {
    margin-bottom: rem(32);
  }

  .settings {
    margin-bottom: rem(24);
  }

  .profile-info-wrapper {
    @include flex;
    padding-bottom: rem(32);
    margin-bottom: rem(32);
    border-bottom: $rem1 solid $user-heading-tab;

    .profile-image {
      width: rem(80);
      height: rem(80);
      border: $rem3 solid $user-unchecked-border;
      margin-right: $rem12;

      .profile-image-container {
        @include fsb(22);
        width: rem(67);
        height: rem(67);
      }
    }

    .details-wrapper {
      margin-top: $rem6;
      color: $signin-heading-color;

      & > div:not(:last-child) {
        margin-bottom: $rem6;
      }

      .name {
        @include fsb(13);
      }

      .email {
        @include fm(12);
      }

      .user-type {
        @include fm(11);
        color: $cancel-button-color;
      }
    }

    .button {
      @include flex;
      margin-top: $rem4;
      align-self: flex-start;
      margin-left: auto;

      button {
        @include flex;
        width: auto;
        height: auto;
        background-color: $transparent;
        border: none;
      }
      .name-m-edit-icon {
        width: rem(16);
        height: rem(16);
      }
    }
  }

  .settings-wrapper {
    margin-bottom: rem(30);
    border-bottom: $rem1 solid $user-heading-tab;

    .setting {
      @include flex(center, space-between);
      @include fm(14);

      &:first-child {
        margin-bottom: rem(32);
      }

      &:last-child {
        margin-bottom: rem(29);
      }

      .m-back-button-wrapper {
        transform: rotateZ(180deg);

        svg {
          width: rem(16);
          height: rem(16);

          path {
            stroke: $axiom-input-label-color;
            stroke-width: 3;
          }
        }
      }
    }
  }

  .log-out {
    @include fm(14);
    @include flex;
    width: auto;
    height: auto;
    background-color: $transparent;
    border: none;
    color: $axiom-input-label-color;
  }
}