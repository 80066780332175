//mixin to use padding and margins
$xs: 10px;
$small: 25px;
$medium: 50px;
$large: 75px;
$xlarge: 100px;
$xxlarge: 150px;

@mixin space($type: margin, $direction: all, $amount: $medium) {
  @if $type == padding {
    @if $direction == all {
      padding: $amount;
    } @else if $direction == top {
      padding-top: $amount;
    } @else if $direction == left {
      padding-left: $amount;
    } @else if $direction == right {
      padding-right: $amount;
    } @else {
      padding-bottom: $amount;
    }
  } @else {
    @if $direction == all {
      margin: $amount;
    } @else if $direction == top {
      margin-top: $amount;
    } @else if $direction == left {
      margin-left: $amount;
    } @else if $direction == right {
      margin-right: $amount;
    } @else {
      margin-bottom: $amount;
    }
  }
}

//mixin to centre a block element
@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}

//mixin to truncate text
@mixin truncate-text {
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
