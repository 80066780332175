@import "~assets/styles/common";

.axiom-user-wrapper {
  padding: rem(30) 0 rem(30) rem(100);
  align-items: center;
  position: relative;
  cursor: pointer;

  .user-details {
    @include flex;
  }
  .hide-details {
    opacity: 0.15;
  }
  .checkbox-wrapper {
    display: none;
    position: absolute;
    top: 50%;
    left: rem(23);
    transform: translateY(-50%);
    width: auto;
    padding: rem(0);

    &.active {
      display: block;
    }
  }

  .icons-wrapper {
    display: block;
    position: absolute;
    top: 50%;
    right: rem(46);
    transform: translateY(-50%);

    .delete-icon {
      display: none;
      position: relative;

      .delete-tooltip {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      svg {
        fill: $close-btn-color;
        width: rem(32);
        height: rem(32);
      }

      &:hover svg,
      &.highlight svg {
        fill: $user-heading-tab-txt;
      }

      &.highlight {
        display: block;

        &+.more-options {
          display: none;
        }
      }
    }

    button {
      border: none;
      background-color: inherit;
      cursor: pointer;
    }
  }

  .name,
  .email,
  .role,
  .doj,
  .inviter {
    @include fm(15);
    color: $user-list-text;
    width: 10%;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: $rem5;
    white-space: nowrap;
  }

  .name,
  .inviter {
    width: 15%;
  }

  .role {
    width: 12%;
  }

  .email {
    width: 25%;
  }

  .doj,
  .pending-invitation {
    flex: 1;
  }

  .pending-invitation {
    @include flex(center, flex-start);

    .circle {
      display: inline-block;
      border-radius: 50%;
      width: $rem10;
      height: $rem10;
      background-color: $invitation-pending-color;
      margin-right: $rem6;
    }
    .text {
      @include fsb(14);
      color: $invitation-pending-color;
      padding: 0;
    }

    .resend-btn-wrapper {
      position: absolute;
      right: rem(108);
    }
    .save-button {
      @include fsb(13);
      width: rem(108);
      height: rem(36);
    }

    .pending-confirmation {
      position: absolute;
      top: 130%;
      z-index: 1;
      right: 0;

      .text {
        color: $axiom-primary-color;
      }

      .popup-accept-button {
        background-color: $axiom-primary-color;
        border: none;
      }

      .popup-cancel-button {
        color: $axiom-primary-color;
        border-color: $axiom-primary-color;
      }

      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: rem(-6);
        right: rem(50);
        border-left: rem(6) solid transparent;
        border-right: rem(6) solid transparent;
        border-bottom: rem(6) solid $white-color;
        transform-origin: 0 100%;
        transform: rotate(360deg);
      }
    }
  }

  .more-options {
    @include flex-center;

    svg {
      width: rem(32);
      height: rem(32);
      fill: $close-btn-color;
    }


  }

  &:not(:last-child) {
    border-bottom: rem(2) solid $light-grey-op2;
  }

  &:hover {
    box-shadow: rem(0) $rem4 $rem10 0 $user-list-hover-shadow;

    .checkbox-wrapper {
      display: block;
    }

    .delete-icon {
      display: block;
    }

    .more-options {
      display: none;
    }
  }
}

.indicator {
  position: absolute;
  top: 40%;
  left: rem(65);
  width: rem(16);
  height: rem(16);
  border-radius: 50%;
}
.internal {
  background-color: $axiom-primary-color;
}
.external {
  background-color: $external-users-indicator;
}
.delete-confirmation {
  position: absolute;
  right: 0;
  top: 75%;
  z-index: 1;

  &::after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    top: rem(-6);
    right: rem(56);
    border-left: rem(6) solid transparent;
    border-right: rem(6) solid transparent;
    border-bottom: rem(6) solid $white-color;
    transform-origin: 0 100%;
    transform: rotate(360deg);
  }
}
.user-restore-wrapper {
  display: block;
  position: absolute;
  top: 50%;
  right: rem(108);
  transform: translateY(-50%);

  .delete-text {
    @include fm(14);
    color: $input-error-indicator;
    margin-right: rem(31);

    .delete-indicator {
      height: rem(10);
      width: rem(10);
      display: inline-block;
      border-radius: 50%;
      background-color: $input-error-indicator;
      margin-right: rem(6);
    }
  }
  .restore-button {
    @include fm(13);
    height: rem(36);
    width: rem(108);
    color: $white-color;
    background-color: $axiom-primary-color;
  }
}
.user-restore div {
  display: inline-block;
}
