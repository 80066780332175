@import "~assets/styles/common";

.axiom-overlay-container {
  width: $viewport-width;
  height: $viewport-height;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $transparent;
  z-index: 1;
  overflow: hidden;
  transition: all 0.5s;

  &.open {
    background-color: $dark-overlay;
  }
}
