@import "~assets/styles/common";

.axiom-notification-wrapper {
  padding: rem(20);
  border-radius: rem(16);
  cursor: pointer;
  margin-bottom: rem(15);

  &.not-viewed {
    background-color: $inner-box-color;
  }

  .row-1 {
    @include flex(center, space-between);
    margin-bottom: $rem4;

    .average-size {
      width: auto;
      height: auto;
      margin-right: rem(20);

      .profile-image-container {
        @include fsb(18);
        width: rem(48);
        height: rem(48);
      }
    }

    .text {
      @include fr(14);
      width: 100%;
      color: $signin-heading-color;

      .highlight {
        @include fsb(14);
      }
    }

    .img-wrapper {
      @include flex-center;
      margin-left: rem(16);

      img {
        border-radius: $rem10;
      }
    }
  }

  .row-2 {
    @include fr(12);
    margin-left: rem(68);
    color: $cancel-button-color;
  }
}
