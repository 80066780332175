@import "~assets/styles/common";

.axiom-group-wrapper {
  width: rem(328);
  height: rem(220);
  border-radius: rem(16);
  padding: rem(32);
  background-color: $white-color;
  margin: 0 rem(24) rem(24) 0;
  cursor: pointer;

  .title-more-options-wrapper {
    @include flex(center, space-between);
    margin-bottom: $rem5;

    .title {
      @include fsb(22);
      @include truncate-text;
      color: $user-heading-tab-txt;
      height: rem(31);
      max-width: rem(208);
    }

    .more-options {
      @include flex-center;
      width: rem(32);
      height: rem(32);
      flex: 0 0 rem(32);
      background-color: $axiom-body-background-color;
      border-radius: $rem8;
      position: relative;
      cursor: pointer;

      .middle-dot {
        width: $rem4;
        height: $rem4;
        background-color: $close-btn-color;
        position: relative;
        border-radius: 50%;

        &.dots-active {
          background-color: $signin-heading-color;
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          width: inherit;
          height: inherit;
          background-color: inherit;
          border-radius: inherit;
        }

        &::before {
          top: 200%;
        }

        &::after {
          bottom: 200%;
        }
      }

      .group-container {
        position: absolute;
        top: calc(100% + #{$rem8});
        right: rem(-12);

        .group-popup {
          position: absolute;
          top: 0;
          right: 0;
          border-radius: $rem7;
          width: rem(141);
        }

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: rem(-8);
        }

        &::before {
          width: 0;
          height: 0;
          right: rem(24);
          border: $rem4 solid transparent;
          border-bottom: $rem4 solid $white-color;
          z-index: 2;
        }

        // added so that the popup doesn't disappear when moving out of the region (added to increase the region of hovering)
        &::after {
          width: rem(32);
          height: rem(10);
          right: $rem12;
          z-index: 1;
        }
      }

      .delete-confirmation {
        right: rem(-40);
        top: rem(40);
        // to display the upward arrow above the Confirmation popup
        z-index: 3;

        &::after {
          right: rem(50);
          top: rem(-4);
        }
      }
    }
  }

  .members-count {
    @include fm(13);
    line-height: rem(22);
    color: $axiom-input-label-color;
    margin-bottom: rem(60);
  }

  .members {
    @include flex(center, flex-start);

    .tooltipContent {
      text-transform: capitalize;
    }

    .shown-user {
      .profile-group {
        .profile-icon-container {
          @include fm(14);
          width: rem(36);
          height: rem(36);
          background: $user-name-group-light;
          color: $signin-text-color;
          opacity: 1;
          text-align: center;
          letter-spacing: $rem1;
        }
      }

      .container .tooltipContent.top {
        bottom: calc(100% + #{$rem12});
      }

      &:nth-child(odd) {
        margin-right: $rem10;
      }

      &:nth-child(even) {
        margin-right: $rem9;
      }

      &:first-child {
        .container .tooltipContent.top {
          transform: none;
          left: rem(-10);

          &::after {
            left: rem(17);
          }
        }
      }
    }

    .more-users-container {
      .container .tooltipContent.left,
      .container .tooltipContent.right {
        padding: rem(24) rem(40) rem(26) rem(20);
      }

      .container .tooltipContent.left {
        right: auto;
      }

      .container .tooltipContent.right {
        left: calc(100% + #{$rem10});
        top: -50%;
        transform: none;

        &::after {
          top: rem(25);
        }
      }

      .member-name {
        @include fm(12);
        text-align: left;
        color: $axiom-body-background-color;
        &:not(:last-child) {
          margin-bottom: rem(16);
        }
      }

      .more-wrapper {
        @include flex-center;
        width: rem(36);
        height: rem(36);
        border: rem(1.5) solid $axiom-primary-color;
        background-color: $white-color;
        border-radius: 50%;

        span {
          @include fsb(13);
          color: $axiom-primary-color;
          letter-spacing: $rem1;
          line-height: $rem13;
        }
      }

      &.active {
        .more-wrapper {
          background-color: $axiom-primary-color;

          span {
            color: $white-color;
          }
        }
      }
    }
  }
}
