@import "~assets/styles/common";

.axiom-grouplist-container {
  // rem(260) is the height of the header
  margin-top: rem(48);
  height: calc(#{$viewport-height} - #{rem(260)});
  overflow: auto;

  .observer {
    @include fsb(12);
    width: inherit;
    min-height: $rem2;
    text-align: center;
    padding: $rem10 0;
  }

  .group-list-wrapper {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: rem(24);
  }

  /* check to see if browser supports css grid */

  @supports (display: grid) {
    .group-list-wrapper {
      display: grid;
      grid-template-columns: repeat(auto-fill, rem(328));
      grid-gap: rem(24);

      .axiom-group-wrapper {
        margin: 0;
      }
    }
  }
}
