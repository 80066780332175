@import "~assets/styles/common";

.axiom-userheading-container {
  background-color: $user-heading-tab;
  padding: rem(32) rem(0) rem(31) rem(100);
  border-top-left-radius: rem(15);
  border-top-right-radius: rem(15);

  &.users-list {
    margin-top: rem(48);

    // reducing the width of the name column
    .user-heading.name,
    .user-heading.inviter {
      width: 15%;
    }

    .user-heading.role {
      width: 12%;
    }

    // increasing the width of the email column
    .user-heading.email {
      width: 25%;
    }

    .pending-invitation,
    .user-heading.doj {
      flex: 1;
    }

    .user-heading.doj {
      padding-right: rem(16);
    }

  }
  
  &.feedback-list {
    // reducing the width of the name column
    .user-heading.name{
      width: 20%;
    }

    .user-heading.type,
    .user-heading.platform {
      width: 13%;
    }

    .user-heading.date {
      width: 20%;
    }

    .user-heading.description {
      width: 30%;
    }
  }

  .heading-wrapper {
    @include flex;

    .user-heading {
      @include fsb(18);
      color: $user-heading-tab-txt;
      width: 25%;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: $rem5;
      white-space: nowrap;
      height: rem(25);

      .text {
        @include fsb(18);
        position: relative;
        display: inline-block;
        cursor: pointer;

        .dropdown-icon {
          position: absolute;
          right: (-#{rem(30)});
          top: 50%;
          transform: translateY(-50%);
          width: rem(28);
          height: rem(28);

          svg {
            width: rem(28);
            height: rem(28);

            path:nth-child(2),
            path:nth-child(3) {
              fill: $close-btn-color;
            }
          }
        }
      }

      &.active {
        .dropdown-icon {
          svg {
            path:nth-child(2) {
              fill: $primary-text-color;
            }
          }
        }
      }

      &.active.arrow-down {
        svg {
          path:nth-child(2) {
            fill: $close-btn-color;
          }

          path:nth-child(3) {
            fill: $primary-text-color;
          }
        }
      }
    }
  }
}
