@import "~assets/styles/common";

.axiom-individual-feedback-wrapper {
  @include flex(center);
  padding: rem(30) 0 rem(30) rem(100);
  cursor: pointer;
  position: relative;

  &.completed {
    background-color: $light-grey-op1;
  }

  &:hover {
    box-shadow: rem(0) $rem4 $rem10 0 $user-list-hover-shadow;

    .icons-wrapper {
      .delete-icon,
      .checkbox-wrapper {
        display: block;
      }

      .more-options {
        display: none;
      }
    }
  }

  &:not(:last-child) {
    border-bottom: rem(2) solid $light-grey-op2;
  }

  .created-by,
  .feedback-type,
  .platform,
  .date,
  .description {
    @include fm(15);
    @include truncate-text;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    white-space: initial;
    color: $user-list-text;
    width: 10%;
    padding-right: $rem5;
  }

  .created-by {
    width: 20%;
  }

  .feedback-type,
  .platform {
    width: 13%;
  }
  .date {
    width: 20%;
  }

  .description {
    width: 25%;
  }

  .icons-wrapper {
    @include flex-center;
    position: absolute;
    top: 50%;
    right: rem(46);
    transform: translateY(-50%);

    .more-options {
      @include flex-center;

      svg {
        width: rem(32);
        height: rem(32);
        fill: $close-btn-color;
      }
    }

    .delete-icon {
      display: none;
      position: relative;

      .delete-tooltip {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }

      .delete-btn {
        @include flex;
        height: auto;

        .name-delete-icon {
          @include flex;
        }
      }

      svg {
        fill: $close-btn-color;
        width: rem(32);
        height: rem(32);
      }

      &:hover svg,
      &.highlight svg {
        fill: $user-heading-tab-txt;
      }

      &.highlight {
        display: block;

        & + .more-options {
          display: none;
        }
      }
    }

    .checkbox-wrapper {
      display: none;
      margin-right: rem(10);
    }

    button {
      border: none;
      background-color: inherit;
      cursor: pointer;
      width: auto;
    }
  }

  .delete-confirmation-wrapper {
    position: absolute;
    right: 0;
    top: 75%;
    cursor: default;
    z-index: 1;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: rem(-5);
      right: rem(56);
      border-left: rem(6) solid transparent;
      border-right: rem(6) solid transparent;
      border-bottom: rem(6) solid $white-color;
      transform-origin: 0 100%;
      transform: rotate(360deg);
    }
  }

  .options {
    position: relative;

    .btn-wrapper {
      .more-icon {
        @include flex-center;
      }

      button {
        @include flex;
        width: auto;
        height: auto;
        background-color: $transparent;
        border: none;
      }

      svg {
        width: rem(48);
        height: rem(48);
      }
    }

    .options-container {
      position: relative;

      .feedback-popup {
        top: #{rem(-5)};
      }

      &::after {
        content: "";
        position: absolute;
        top: $rem10;
        width: 0;
        height: 0;
        right: rem(20);
        top: #{rem(-13)};
        border: $rem4 solid $transparent;
        border-bottom: $rem4 solid $white-color;
        z-index: 2;
      }
    }

    .delete-confirmation-wrapper {
      .axiom-confirmation-popup {
        position: absolute;
        right: #{rem(-38)};
      }
    }
  }
}
