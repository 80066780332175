@import "~assets/styles/common";

.sign-in {
  @include flex-row;
  width: $viewport-width;
  height: $viewport-height;
  overflow: hidden;

  .sign-in-img {
    @include flex-center;
    // 608px is the width of .sign-in-details
    width: calc(100% - #{rem(608)});
    background-color: $axiom-body-background-color;

    img {
      width: 100%;
      max-width: rem(1079);
    }
  }

  .sign-in-details {
    @include flex-center;
    background-color: $white-color;
    width: rem(608);
    flex: none;
    padding: 0 rem(104);
    height: 100%;
  }

  .sign-in-heading {
    @include fsb(26, null, null, null);
    height: 10.5%;
    line-height: normal;
    letter-spacing: normal;
    color: $signin-heading-color;
  }

  .sign-in-text {
    @include fr(15, null, null, null);
    width: 100%;
    height: 5%;
    margin-top: rem(20);
    letter-spacing: normal;
    color: $signin-text-color;
  }

  .logo {
    width: rem(106);
    height: rem(137);
    margin-bottom: rem(54);
    object-fit: contain;

    img {
      width: rem(105);
      height: rem(136);
    }
  }

  .sign-in-button {
    margin-top: rem(44);
  }

  .sign-in-btn-wrapper {
    @include flex-center;
    height: rem(64);
    background-color: $light-grey-op2;
    text-decoration: none;
    border-radius: $rem8;

    span {
      @include fsb(18);
      color: $signin-heading-color;
    }
  }

  .google-img {
    width: rem(28);
    height: rem(28);
    margin: 0 rem(24) 0 0;
  }
}
