@import "~assets/styles/common";

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button {
  cursor: pointer;
}
.common-button {
  @include fsb(18);
  width: rem(198);
  height: rem(56);
  border-radius: rem(8);
  border: solid rem(2) $axiom-primary-color;
  cursor: pointer;

  &.default {
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
  }
}
.save-button {
  color: $white-color;
  background-color: $axiom-primary-color;
}
.cancel-button {
  color: $axiom-primary-color;
  background-color: $white-color;
}
.button-add {
  width: rem(52);
  height: rem(52);
  cursor: pointer;
}
.popup-accept-button {
  @include fsb(13);
  width: rem(118);
  height: rem(36);
  border-radius: rem(6);
  border: solid rem(2);
  cursor: pointer;
  color: $white-color;
  background-color: $black-color;
  border-color: $black-color;
  margin-right: rem(20);
}
.popup-cancel-button {
  @include fsb(13);
  width: rem(118);
  height: rem(36);
  border-radius: rem(6);
  border: solid rem(2);
  cursor: pointer;
  color: $black-color;
  background-color: $white-color;
  border-color: $cancel-button-color;
}
