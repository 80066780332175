@import "~assets/styles/common";

.bottom-nav-bar-container {
  @include flex-center;
  @extend .max-mobile-width;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  height: rem(96);
  box-shadow: $rem6 0 rem(22) $filter-dropdown-shadow;
  border-top-left-radius: rem(27);
  border-top-right-radius: rem(27);
  background-color: $white-color;
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
  margin: 0 auto;

  &.move-down {
    bottom: rem(-150);
  }
}
