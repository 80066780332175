@import "~assets/styles/common";

.m-input-password-wrapper {
  .password-wrapper {
    position: relative;

    input {
      @include fm(14);
      color: $user-heading-tab-txt;
      caret-color: $axiom-primary-color;
    }

    .eye-wrapper {
      position: absolute;
      top: 50%;
      right: 18px;
      transform: translate(0, -50%);

      .button {
        @include flex;
        
        button {
          width: auto;
          height: auto;
          background-color: $transparent;
          border: none;
        }

        .eyes {
          @include flex;
        }
      }

    }
  }

  .password-error {
    @include fm(10);
    display: block;
    color: $input-error-indicator;
    margin: $rem4 0 $rem12;
  }
}