@import "~assets/styles/common";

input {
  @include fm(15);
  color: $signin-heading-color;
}
.axiom-input-field {
  position: relative;
  width: rem(420);
  height: rem(52);
  border-radius: rem(8);

  input {
    width: 100%;
    height: 100%;
    font-size: 1rem;
    padding-left: rem(16);
    border-radius: rem(8);
    border: $rem1 solid $axiom-input-background-color;
  }
  input:focus {
    outline: none;
    border-color: $axiom-primary-color;
  }
}
.axiom-input-label {
  @include fm(12);
  display: inline-block;
  color: $axiom-input-label-color;
  margin-top: rem(32);
  margin-bottom: rem(12);
}
