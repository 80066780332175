@import "~assets/styles/common";

.user-device-details {
  @include flex(center, null);
  @include fm(15);
  color: $user-list-text;
  padding: rem(34) 0 rem(31) rem(48);

  &:not(:last-child) {
    border-bottom: rem(2) solid $border-color;
  }

  .model-text,
  .name-text {
    width: rem(201);
    padding-right: $rem5;
  }
  
  .version-text {
    width: rem(140);
    padding-right: $rem5;
  }

  .delete-device-wrapper {
    position: relative;

    .delete-device-button {
      height: rem(32);
      width: rem(32);
      cursor: pointer;

      button {
        border: none;
        background-color: inherit;
        cursor: pointer;
      }

      .name-delete-icon {
        @include flex;
      }

      svg {
        fill: $close-btn-color;
        width: rem(32);
        height: rem(32);
      }

      &:hover svg {
        fill: $user-heading-tab-txt;
      }
    }

    .device-delete-confirmation {
      position: absolute;
      top: 115%;
      right: rem(-40);
      z-index: 1;

      .hidden {
        display: block;
      }
      &::after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        top: rem(-6);
        right: rem(40);
        border-left: rem(6) solid transparent;
        border-right: rem(6) solid transparent;
        border-bottom: rem(6) solid $white-color;
        transform-origin: 0 100%;
        transform: rotate(360deg);
      }
    }
  }
}
