@import "~assets/styles/common";

.axiom-pill-btn-container {
  display: inline-block;
  border-radius: $rem8;

  .pill-wrapper {
    @include flex(center, space-between);
    height: inherit;

    .text {
      @include fsb(13);
      @include truncate-text;
      margin-right: $rem9;
    }

    .clear-icon {
      @include flex-center;
      cursor: pointer;

      .name-clear-icon {
        @include flex-center;
      }
    }

    .styled-cl {
      &.highlight {
        svg circle {
          fill: $signin-heading-color;
        }
        .container .tooltipContent.top {
          bottom: calc(100% + #{$rem12});
        }
      }
    }
  }

  &.sm {
    background-color: $sidebar-background-color;
    height: rem(28);
    padding: 0 $rem8 0 $rem10;
    margin: 0 rem(16) $rem4 0;

    svg {
      width: rem(16);
      height: rem(16);

      circle {
        fill: $input-text-color;
      }
      g {
        fill: $sidebar-background-color;
      }
    }
  }

  &.md {
    background-color: $axiom-body-background-color;
    height: rem(40);
    padding: 0 rem(16);
    margin: 0 rem(14) rem(16) 0;

    .pill-wrapper {
      .text {
        margin-right: rem(20);
      }
    }

    svg {
      width: rem(20);
      height: rem(20);

      circle {
        fill: $input-text-color;
      }
      g {
        fill: $sidebar-background-color;
      }
    }
  }
}
