@import "~assets/styles/common";

.m-navbar-container {

  @include flex(center, space-between);
  width: 100%;
  padding: 0 19%;
  
  .button {
    @include flex;
    
    button {
      width: auto;
      height: auto;
      background-color: $transparent;
      border: none;

      &.active {
        path {
          fill: $axiom-primary-color;
        }

        .nav-icon-name {
          color: $axiom-primary-color;
        }
      }
    }
  }
  
  .m-nav-btn {
    @include flex-column(center, null);
  
    .nav-icons {
      @include flex;
      margin-bottom: $rem4;
      
      svg {
        width: rem(24);
        height: rem(24);
        fill: $cancel-button-color;
      }
    }
  
    .nav-icon-name {
      @include fsb(10);
      color: $cancel-button-color;
    }
  
  }
}