@import "~assets/styles/common";

.m-device-container {
  position: relative;
  border-radius: rem(16);
  margin-bottom: $rem12;
  background-color: $red;
  height: rem(80);

  .device-wrapper {
    @include flex;
    position: absolute;
    width: 100%;
    height: rem(80);
    padding: $rem12 rem(21) $rem12 $rem12;
    background-color: $inner-box-color;
    border-radius: rem(16);
    cursor: pointer;
    z-index: 1;

    .phone-icon {
      @include flex-center;
      width: rem(56);
      height: rem(56);
      background-color: $axiom-primary-color;
      border-radius: $rem10;
      margin-right: $rem12;

      .android,
      .ios {
        @include flex;
      }

      .android svg {
        width: rem(19);
        height: rem(40);
      }

      .ios svg {
        width: rem(20);
        height: rem(40);
      }
    }

    .model-name-wrapper {
      @include flex-column(null, space-between);
      width: 100%;
      padding: $rem4 0;

      .model {
        @include fsb(15);
        color: $user-heading-tab-txt;
      }

      .name-device-wrapper {
        @include flex(center, space-between);
      }

      .name-version {
        @include fm(12);
        color: $axiom-input-label-color;
      }

      .current-device {
        @include flex;
        margin-left: auto;

        .text {
          @include fsb(10);
          color: $user-checked-green;
          align-self: flex-end;

          &::after {
            content: "";
            display: inline-block;
            position: relative;
            width: $rem6;
            height: $rem6;
            margin-left: $rem6;
            border-radius: 50%;
            background: $user-checked-green;
          }
        }
      }
    }
  }

  .button {
    position: absolute;
    right: rem(30);
    top: 50%;
    transform: translateY(-50%);
    @include flex;

    .delete-btn {
      width: auto;
      height: auto;
      border: none;
      background-color: $transparent;
    }

    .delete-icon {
      @include flex;
      pointer-events: none;

      svg {
        width: rem(24);
        height: rem(24);
        fill: $white-color;
      }
    }
  }
}
