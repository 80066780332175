@import "~assets/styles/common";

.axiom-alert-msg-container {
  @include flex-center;

  .icon {
    margin-right: $rem12;

    .center {
      @include flex;
    }

    svg {
      width: rem(24);
      height: rem(24);
    }
  }

  .msg-text {
    @include fsb(16);
    color: $primary-text-color;
    margin-right: rem(64);
  }

  .close-btn {
    @include fsb(16);
    border: none;
    color: $close-btn-color;
    background-color: $sidebar-background-color;
    cursor: pointer;
    width: auto;
    height: auto;
    position: static;
    top: 0;
    right: 0;
  }
}
