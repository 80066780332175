@import "~assets/styles/common";

.filter-dropdown {
  position: relative;
  display: inline-block;
  margin-right: rem(16);

  .filter-icon {
    position: relative;
    width: rem(52);
    height: rem(52);
    cursor: pointer;
  }
  .dropdown {
    position: absolute;
    top: rem(65);
    left: 0;
    min-width: rem(180);
    margin: 0 rem(10);
    transform: translateX(rem(-75));
    border-radius: rem(8);
    box-shadow: 0 rem(6) rem(26) 0 $filter-dropdown-shadow;
    background-color: $white-color;
    z-index: 1;
    padding: rem(24) rem(20);
  }
  .dropdown::after {
    content: "";
    position: absolute;
    top: rem(-10);
    left: rem(90);
    margin-left: rem(-5);
    border-width: rem(6);
    border-style: solid;
    border-color: transparent transparent white transparent;
  }
  .dropdown-title {
    @include fm(13);
    @include flex(center, space-between);
    color: $primary-text-op-4;
    margin-bottom: rem(18);

    .clear-text {
      color: $axiom-primary-color;
      cursor: pointer;
    }
  }
  .checkbox-text:not(:last-child) {
    margin-bottom: $rem15;
  }
  .dropdown-content:not(:first-child) {
    padding-top: $rem15;
  }
  .dropdown-content:not(:last-child) {
    padding-bottom: rem(20);
    border-bottom: solid rem(1.5) $filter-border-color;
  }
  .text-box {
    margin-top: rem(-3);
  }
}
