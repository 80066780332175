@import "~assets/styles/common";

.m-build-details-container {
  .name-type {
    @include fsb(22);
    color: $primary-text-color;
    margin-bottom: rem(28);
    word-break: break-all;
  }

  .text,
  .title {
    @include fsb(16);
    color: $primary-text-color;
    margin-bottom: rem(20);
  }

  .basic-wrapper {
    @include flex(center);
    margin-bottom: rem(24);

    &:last-child {
      margin-bottom: rem(28);
    }

    .key {
      @include fm(12);
      color: $axiom-input-label-color;
      width: 37%;
    }

    .value {
      @include fm(13);
      color: $signin-text-color;
      word-break: break-all;
    }
  }

  .info-wrapper {
    padding: rem(24) 0 rem(28);
    border-top: $rem1 solid $solitude;
    word-break: break-all;

    &.commit-msg-wrapper {
      .value {
        white-space: pre-line;
      }
    }

    .title {
      margin-bottom: rem(16);
    }

    .value {
      @include fr(13, 24);
      color: $user-list-text;

      ul {
        li {
          list-style-position: inside;
        }
      }
    }
  }

  .install-btn-wrapper {
    @include flex-center;
    width: 100%;

    .close-btn-wrapper {
      margin: 0 $rem10 0 rem(20);

      .close-btn {
        @include flex;
        width: auto;
        height: auto;
        background-color: $transparent;
        border: none;
      }

      .close-icon {
        @include flex-center;

        svg {
          width: rem(48);

          circle {
            fill: $white-color;
          }
        }
      }
    }

    .button {
      @include flex-center;
      width: 100%;
    }

    .install {
      @include flex-center;
      @include fsb(16);
      max-width: rem(327);
      width: inherit;
      height: rem(52);
      background-color: $axiom-primary-color;
      color: $white-color;
      text-decoration: none;
      border-radius: $rem10;
      text-align: center;
    }

    .meter {
      width: 100%;
      box-sizing: content-box;
      height: rem(20);
      position: relative;
      background: $white-color;
      border-radius: rem(25);
      padding: $rem10;
      box-shadow: inset 0 -$rem1 $rem1 rgba(255, 255, 255, 0.3);
    }
    .meter > span {
      @include fm(13);
      display: block;
      color: $white-color;
      height: 100%;
      border-radius: rem(20) $rem8 $rem8 rem(20);
      background-color: rgb(43, 194, 83);
      background-image: linear-gradient(
        center bottom,
        rgb(43, 194, 83) 37%,
        rgb(84, 240, 84) 69%
      );
      box-shadow: inset 0 $rem2 $rem9 rgba(255, 255, 255, 0.3),
        inset 0 -$rem2 $rem6 rgba(0, 0, 0, 0.4);
      position: relative;
      overflow: hidden;
      text-align: center;
    }
    .meter > span:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        -45deg,
        rgba(255, 255, 255, 0.2) 25%,
        transparent 25%,
        transparent 50%,
        rgba(255, 255, 255, 0.2) 50%,
        rgba(255, 255, 255, 0.2) 75%,
        transparent 75%,
        transparent
      );
      z-index: 1;
      background-size: rem(50) rem(50);
      animation: move 2s linear infinite;
      border-radius: rem(20) $rem8 $rem8 rem(20);
      overflow: hidden;
    }

    @keyframes move {
      0% {
        background-position: 0 0;
      }
      100% {
        background-position: rem(50) rem(50);
      }
    }
  }
}
