@import "~assets/styles/common";

.m-notification-container {
  @extend .mobile-wrapper;

  .loading-text {
    @include fsb(12);
    text-align: center;
  }

  .no-new-notifications {
    @include fsb(16);
  }

  // notification styles
  .axiom-notification-wrapper {
    padding: $rem12 $rem9;

    &:not(:last-child) {
      margin-bottom: $rem10;
    }

    .row-1 {
      flex-direction: row-reverse;

      .average-size {
        margin-right: $rem12;
        display: none;

        .profile-image-container {
          @include fsb(12);
          width: rem(32);
          height: rem(32);
        }
      }

      .text {
        @include fr(12);

        .highlight {
          @include fsb(12);
        }
      }

      .img-wrapper {
        margin: 0 $rem12 0 0;

        img {
          width: rem(40);
          height: rem(40);
        }
      }
    }

    .row-2 {
      @include fsb(10);
      margin-left: rem(52);
    }
  }

}