@import "~assets/styles/common";

.axiom-m-toast-container {
  width: 100%;
  position: fixed;
  top: 50%;
  left: initial;
  transform: translateY(-50%);
  z-index: 2;

  .toast-wrapper {
    @include flex-center;
    @extend .max-mobile-width;
    height: rem(60);
    padding: rem(40) rem(20);
    box-shadow: $rem6 0 rem(22) $filter-dropdown-shadow;
    background-color: $white-color;
    transform: translateX(-200%);
    transition: transform 0.5s;

    &.move-center {
      animation: move-center 0.5s ease-in 0s 1 forwards;
    }

    &.move-right {
      animation: move-right 0.5s ease-in 0s 1 forwards;
    }

    .icon {
      margin-right: rem(20);

      .center {
        @include flex;
      }

      svg {
        width: rem(20);
        height: rem(20);
      }
    }

    .msg {
      @include fsb(16);
      color: $primary-text-color;
    }
  }

  @keyframes move-center {
    100% {
      transform: translateX(0);
    }
  }
  
  @keyframes move-right {
    100% {
      transform: translateX(200%);
    }
  }
}
