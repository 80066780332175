@import "~assets/styles/common";

.m-sign-in-container {
  @include flex-column;
  width: 100%;
  padding: 0 rem(32) rem(64);
  height: $viewport-height;

  .m-logo {
    margin-top: rem(116);

    img {
      width: rem(80);
      height: rem(104);
      transition: all 0.3s;
    }

    &.small {
      margin-top: rem(72);

      img {
        width: rem(62);
        height: rem(80);
      }
    }
  }

  .m-sign-in-form-wrapper {
    margin-top: auto;

    .m-sign-in {
      .axiom-input-field {
        margin-bottom: rem(16);

        &.no-margin {
          margin-bottom: 0;
        }

        &.error {
          input {
            border-color: $input-error-indicator;
          }
        }
      }

      .login-error {
        @extend .input-error;
        margin: $rem4 0 $rem12;
      }

      .m-forgot-password {
        &.hide {
          visibility: hidden;
        }

        .button {
          text-align: center;
          margin-bottom: rem(40);
        }

        button {
          @include fm(12);
          width: auto;
          height: auto;
          text-align: center;
          color: $axiom-input-label-color;
          background-color: $transparent;
          border: none;
        }
      }

      .m-sign-in-btn {
        .button {
          text-align: center;
        }

        button {
          @include fsb(16);
          width: 100%;
          color: $white-color;
          background-color: $axiom-primary-color;
        }
      }
    }
  }

  .m-sign-in-text {
    @include fsb(28);
    margin-bottom: rem(24);
  }

  .m-google-popup-login {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: rem(370);
    padding: rem(37) rem(32) rem(64);
    border-top-left-radius: rem(32);
    border-top-right-radius: rem(32);
    background-color: $white-color;
    transition: all 0.3s;
    transform: translateY(100%);
    z-index: 2;

    &.move-up {
      transform: translateY(0);
    }

    .m-close-google-login {
      @include flex(center, flex-end);
      margin-bottom: rem(47);

      button {
        width: auto;
        height: auto;
        background-color: $transparent;
        border: none;
      }

      .close-icon {
        @include flex;
      }
    }

    .text {
      @include fm(15);
      color: $signin-text-color;
      margin-bottom: rem(48);
    }

    .sign-in-button {
      margin-top: rem(44);
    }

    .sign-in-btn-wrapper {
      @include flex-center;
      height: rem(64);
      background-color: $light-grey-op2;
      text-decoration: none;
      border-radius: $rem8;

      span {
        @include fsb(18);
        color: $signin-heading-color;
      }

      .g-icon {
        @include flex;
        margin-right: rem(24);

        svg {
          width: rem(24);
          height: rem(24);
        }
      }
    }

    .google-img {
      width: rem(28);
      height: rem(28);
      margin: 0 rem(24) 0 0;
    }
  }
}
