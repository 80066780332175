@import "~assets/styles/common";

.m-edit-name-wrapper {
  @extend .mobile-wrapper;
  @include flex-column;
  padding-bottom: rem(59);
  height: $viewport-height !important;

  .edit-text {
    @include fsb(28);
    margin-bottom: rem(32);
  }

  .name {
    input {
      @include fm(15);
      color: $user-heading-tab-txt;
      border: none;
      border-radius: 0;
      border-bottom: $rem1 solid $user-heading-tab;
      caret-color: $axiom-primary-color;

      &:focus {
        border-bottom: $rem2 solid $axiom-primary-color;
      }
    }
  }

  .save-btn-wrapper {
    margin-top: auto;

    button {
      @include fsb(16);
      width: 100%;
      height: rem(52);
    }
  }
}
