@import "~assets/styles/common";

.axiom-project-info-container {
  .info-wrapper {
    @include flex(flex-start, space-between);
    margin-bottom: rem(32);

    &.pop-out {
      position: relative;
      z-index: 1;
      background-color: $white-color;
    }

    .icon-content {
      @include flex;

      .icon-wrapper {
        @include flex-center;
        width: rem(120);
        height: rem(120);
        border-radius: rem(16);
        border: $rem4 solid $image-background;
        margin-right: rem(16);

        img {
          width: rem(100);
          height: rem(100);
          border-radius: $rem8;
        }
      }

      .content-wrapper {
        padding-top: $rem8;

        .platform-icons {
          @include flex;
          margin-bottom: $rem6;

          & > div {
            @include flex;
          }

          & > div:not(:last-child) {
            margin-right: $rem12;
          }

          svg {
            width: rem(20);
            height: rem(20);

            path:nth-child(2) {
              fill: $signin-text-color;
            }
          }
        }

        .name {
          @include fsb(20);
          height: rem(28);
          color: $primary-text-color;
        }

        .manager-name {
          @include fr(14);
          height: rem(20);
          color: $signin-text-color;
          margin: $rem6 0;
        }

        .manager-email {
          @include fm(13);
          height: rem(19);
          color: $cancel-button-color;
        }

        .state {
          position: static;
          justify-content: flex-start;
          margin-top: $rem10;
        }
      }
    }

    .more-wrapper {
      margin: 0 rem(16) 0 auto;
      position: relative;

      .more-icon {
        &.active {
          svg {
            g {
              fill: $signin-heading-color;
            }
          }
        }
      }

      .group-container {
        .axiom-list-container.dropdown-list {
          max-height: none;
          border-radius: $rem2;
        }

        &::after {
          content: "";
          position: absolute;
          top: $rem10;
          width: 0;
          height: 0;
          right: rem(20);
          top: 93%;
          border: $rem4 solid $transparent;
          border-bottom: $rem4 solid $white-color;
          z-index: 2;
        }
      }

      .delete-confirmation {
        top: calc(100% + #{$rem5});
        right: rem(-30);

        &::after {
          right: rem(48);
        }

        .axiom-confirmation-popup {
          width: auto;

          .confirmation-box {
            width: max-content;
          }

          .button-confirmation {
            .popup-accept-button {
              background-color: $black-color;
              width: auto;
              padding: 0 rem(20);
            }

            .popup-cancel-button {
              border: $rem1 solid $cancel-button-color;
            }
          }
        }
      }
    }

    .more-wrapper .more-icon,
    .close-btn-wrapper .close-btn {
      width: rem(48);
      height: rem(48);
      cursor: pointer;
    }

    .more-wrapper .btn-wrapper,
    .close-btn-wrapper {
      .button {
        @include flex;

        button {
          border: none;
          background-color: $white-color;
        }
      }
    }
  }

  .description {
    @include fr(13);
    line-height: 1.85;
    color: $signin-text-color;
  }
}
