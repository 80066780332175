@import "~assets/styles/common";

.axiom-datalist-container {
  .search-list-container {
    position: relative;
  }

  .axiom-datalist-label {
    @include fm(12);
    display: inline-block;
    color: $axiom-input-label-color;
    margin-top: rem(32);
    margin-bottom: rem(12);
  }

  &.show-folder {
    .axiom-search {
      .input-wrapper {
        input {
          caret-color: $input-text-color;

          &:focus::placeholder {
            color: $input-text-color;
          }

          &:focus {
            box-shadow: none;
            border: $rem1 solid $search-border;
          }
        }

        .search-icon {
          &.focussed {
            svg {
              path:first-child {
                fill: $input-text-color;
              }
              path:nth-child(2) {
                stroke: $input-text-color;
              }
            }
          }
        }

        .clear-icon {
          right: rem(40);
        }
      }
    }

    .arrow {
      position: absolute;
      top: rem(23);
      right: rem(17);
      display: inline-block;
      height: inherit;
      margin-left: $rem14;
      pointer-events: none;

      svg {
        width: $rem14;
      }

      .name-arrow-down,
      .name-arrow-up {
        @include flex-center;
      }

      .name-arrow-up {
        svg {
          position: relative;
          top: rem(-1);

          path {
            fill: $signin-heading-color;
          }
        }
      }
    }
  }
}
