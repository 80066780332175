@import "~assets/styles/common";

.container {
  position: relative;
  cursor: pointer;
}

.tooltipContent {
  @include fm(13);
  display: grid;
  color: $white-color;
  position: absolute;
  z-index: 1;
  white-space: nowrap;
  min-width: rem(104);
  text-align: center;
  padding: rem(12);
  border-radius: rem(10);
  background-color: $signin-heading-color;
  animation: fadeIn ease-in-out 0.1s;

  &.displace {
    z-index: 3;

    &.top {
      bottom: auto;
    }
    &.right {
      left: auto;
    }
    &.left {
      right: auto;
    }
    &.bottom {
      top: auto;
    }
  }
}

.tooltipContent::after {
  position: absolute;
  width: 0;
  height: 0;
}

.top {
  bottom: calc(100% + #{rem(15)});
  left: 50%;
  transform: translateX(-50%);

  &::after {
    content: "";
    bottom: rem(-8);
    left: calc(50% - #{rem(10)});
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-top: rem(10) solid $signin-heading-color;
    transform-origin: 0 100%;
    transform: rotate(0deg);
  }
}

.right {
  left: calc(100% + #{rem(15)});
  top: 50%;
  transform: translateY(-50%);

  &::after {
    content: "";
    top: calc(50% - #{rem(10)});
    left: rem(-8);
    border-top: rem(10) solid transparent;
    border-bottom: rem(10) solid transparent;
    border-right: rem(10) solid $signin-heading-color;
  }
}

.left {
  top: 50%;
  transform: translateY(-50%);
  right: calc(100% + #{rem(15)});

  &::after {
    content: "";
    top: calc(50% - #{rem(10)});
    right: rem(-8);
    border-top: rem(10) solid transparent;
    border-bottom: rem(10) solid transparent;
    border-left: rem(10) solid $signin-heading-color;
  }
}

.bottom {
  top: calc(100% + #{rem(15)});
  left: 50%;
  transform: translateX(-50%);

  &::after {
    content: "";
    top: rem(-8);
    left: calc(50% - #{rem(10)});
    border-left: rem(10) solid transparent;
    border-right: rem(10) solid transparent;
    border-bottom: rem(10) solid $signin-heading-color;
    transform-origin: 0 100%;
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

// custom classes
.tooltipContent {
  &.text-left {
    .arr-name {
      display: grid;
      grid-template-columns: rem(92) rem(5) 1fr;
      grid-column-gap: rem(10);
      text-align: left;
    }
  }
}
