@import "~assets/styles/common";

.m-header-wrapper {
  @extend .max-mobile-width;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: rem(108);
  padding: rem(32) rem(24) 0;
  margin: 0 auto;
  background-color: $white-color;
  z-index: 2;

  .m-header {
    &.top-margin {
      margin-top: $rem6;
    }

    ul {
      @include flex(center, space-between);
      list-style: none;

      .nav-item {
        @include flex;

        svg {
          width: rem(24);
          height: rem(24);
          fill: $black-color;
        }
      }

      li {
        cursor: pointer;
      }

      li:first-child {
        @include flex;
      }

      .m-axiom-logo {
        img {
          width: rem(40);
          height: rem(40);
        }
      }

      .m-back-btn {
        button {
          @include flex;
        }
      }

      .m-search-icn {
        margin: 0 rem(24) 0 auto;
        svg {
          path:nth-child(1) {
            fill: $black-color;
          }
          path:nth-child(2) {
            stroke: $black-color;
          }
        }
      }

      .m-centered-text {
        @include fsb(18);
        @include truncate-text;
        color: $user-heading-tab-txt;
        padding: 0 $rem10 0 $rem10;
      }

      .last-nav-item {
        width: rem(24);
        height: rem(24);
      }

      .clear {
        @include fsb(12);
        color: $cancel-button-color;
        border: none;
        width: auto;
        height: auto;
        background: $white-color;
      }
    }
  }
}
