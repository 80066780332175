@import "~assets/styles/common";

.m-main {
  .offline-banner {
    @include fsb(14);
    @include flex-center;
    position: absolute;
    height: rem(26);
    top: 0;
    left: 0;
    right: 0;
    transform: translateY(-100px);
    opacity: 0;
    transition: all 0.3s;
    background-color: $light-red;
    color: $white-color;
    z-index: 100;
    border-radius: 0 0 $rem2 $rem2;

    &.show-banner {
      transform: translateY(0);
      opacity: 1;
    }
  }
}
