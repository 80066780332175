@import "~assets/styles/common";

.axiom-userlist-container {
  background-color: $sidebar-background-color;
  // height of the static header (including user-heading-tab)
  height: calc(#{$viewport-height} - #{rem(400)});
  position: relative;
  overflow: auto;

  .empty-users-list {
    @extend .empty-list;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: rem(20);
  }

  .observer {
    @include fsb(12);
    width: inherit;
    min-height: $rem2;
    text-align: center;
    padding: $rem10 0;
  }
}
