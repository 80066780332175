@import "~assets/styles/common";

.axiom-loader {
  .loader {
    position: relative;
    width: rem(60);
    height: rem(60);
    border-radius: 50%;
    margin: rem(75);
    display: inline-block;
    vertical-align: middle;

    &-inner {
      position: absolute;
      border: $rem4 solid $axiom-primary-color;
      border-radius: 50%;
      width: rem(40);
      height: rem(40);
      left: calc(50% - #{rem(20)});
      top: calc(50% - #{rem(20)});
      border-right: 0;
      border-top-color: transparent;
      -webkit-animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
        infinite;
      animation: loader-1-inner 1s cubic-bezier(0.42, 0.61, 0.58, 0.41) infinite;
    }

    &-outter {
      position: absolute;
      border: $rem4 solid $axiom-primary-color;
      border-left-color: transparent;
      border-bottom: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      -webkit-animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
        infinite;
      animation: loader-1-outter 1s cubic-bezier(0.42, 0.61, 0.58, 0.41)
        infinite;
    }
  }

  &.md {
    .loader {
      width: rem(45);
      height: rem(45);
      margin: rem(25) 0;

      &-inner {
        width: rem(22);
        height: rem(22);
        left: calc(50% - #{rem(11)});
        top: calc(50% - #{rem(11)});
      }

      &-inner,
      &-outter {
        border-width: rem(3);
      }
    }
  }

  &.sm {
    .loader {
      width: rem(30);
      height: rem(30);
      margin: $rem10 0;

      &-inner {
        width: rem(15);
        height: rem(15);
        left: calc(50% - #{rem(7.5)});
        top: calc(50% - #{rem(7.5)});
      }

      &-inner,
      &-outter {
        border-width: $rem2;
      }
    }
  }

  &.center {
    @include flex-center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @keyframes loader-1-inner {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(-360deg);
      transform: rotate(-360deg);
    }
  }

  @keyframes loader-1-outter {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  &.inline {
    position: absolute;
    top: 50%;
    right: rem(50);
    transform: translateY(-50%);

    .lds-ring {
      display: inline-block;
      position: relative;
      width: rem(24);
      height: rem(24);
    }

    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: rem(24);
      height: rem(24);
      border: $rem4 solid $axiom-primary-color;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: $axiom-primary-color transparent transparent transparent;
    }

    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }

    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }

    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
