@import "~assets/styles/common";

.m-card-container {
  background-color: $user-heading-tab-light;
  height: rem(64);
  border-radius: rem(16);
  cursor: pointer;

  .m-card-wrapper {
    @include flex(center, space-between);
    height: inherit;
    padding: 0 rem(16) 0 rem(28);

    .name {
      @include fm(15);
      color: $signin-heading-color;
    }
  
    .right-arrow {
      svg {
        width: rem(24);
        height: rem(24);
        transform: rotate(180deg);
  
        path {
          stroke: $axiom-input-label-color;
        }
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: $rem12;
  }
}