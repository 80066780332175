@import "~assets/styles/common";

.axiom-checkbox-container {
  @include flex;

  &.user-list-cb {
    @include flex(center);
    position: relative;
    border-radius: $rem4;
    width: max-content;

    .wrapper {
      @include flex(center);
      cursor: pointer;

      input {
        opacity: 0;
        display: inline-block;
        cursor: pointer;
        width: rem(18);
        height: rem(18);
      }

      .unchecked,
      .checked {
        position: absolute;
        top: 0;
        left: 0;
        width: rem(18);
        height: rem(18);
        border-radius: $rem4;
      }
      .unchecked {
        border: solid rem(1.5) $user-unchecked-border;
      }

      .checked {
        svg {
          width: rem(18);
          height: rem(18);

          rect {
            fill: $user-checked-green;
          }

          path {
            fill: $sidebar-background-color;
          }
        }
      }
    }
  }

  &.filter-cb {
    @include flex(center);
    border-radius: $rem4;
    width: max-content;

    .wrapper {
      @include flex;
      cursor: pointer;
      position: relative;

      .icon-wrapper {
        @include flex-center;
        position: absolute;
        width: rem(16);
        height: rem(16);
        border-radius: $rem4;
        border: rem(2) solid $axiom-primary-color;
        margin-right: rem(12);
        pointer-events: none;

        .inner-box {
          display: inline-block;
          width: $rem10;
          height: $rem10;
          border-radius: $rem2;
          background-color: $axiom-primary-color;
        }
      }
    }

    input {
      opacity: 0;
      display: inline-block;
      cursor: pointer;
      width: rem(16);
      height: rem(16);
    }

    .unchecked,
    .checked {
      position: absolute;
      top: 0;
      left: 0;
      width: rem(16);
      height: rem(16);
      border-radius: $rem4;
    }
    .unchecked {
      border: solid rem(1.5) $user-unchecked-border;
    }

    .checked {
      svg {
        width: rem(16);
        height: rem(16);

        rect {
          fill: $axiom-primary-color;
        }

        path {
          fill: $sidebar-background-color;
        }
      }
    }

    label {
      @include fm(14);
      padding-left: rem(12);
      color: $primary-text-color;
      cursor: pointer;

      &.active {
        color: $axiom-primary-color;
      }
    }
  }

  .checked,
  .unchecked,
  label {
    &.disabled {
      cursor: not-allowed;
    }
  }

  label {
    @include fm(14);
    padding-left: rem(12);
    color: $primary-text-color;
    cursor: pointer;
  }
}
