/// Default px for 1rem
/// @group 300 - configuration
/// @access public
$rem: 16px !default;

/// Rem conversion from 1px. This is equivalant to rem(1).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem1
///   // -> 0.0625rem;
$rem1: #{1px / $rem}rem;

/// Rem conversion from 2px. This is equivalant to rem(2).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem2
///   // -> 0.0625rem;
$rem2: #{2px / $rem}rem;

/// Rem conversion from 3px. This is equivalant to rem(3).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem3
///   // -> 0.1875rem;
$rem3: #{3px / $rem}rem;

/// Rem conversion from 4px. This is equivalant to rem(4).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem4
///   // -> 0.25rem;
$rem4: #{4px / $rem}rem;

/// Rem conversion from 5px. This is equivalant to rem(5).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem5
///   // -> 0.3125rem;
$rem5: #{5px / $rem}rem;

/// Rem conversion from 6px. This is equivalant to rem(6).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem6
///   // -> 0.375rem;
$rem6: #{6px / $rem}rem;

/// Rem conversion from 7px. This is equivalant to rem(7).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem7
///   // -> 0.4375rem;
$rem7: #{7px / $rem}rem;

/// Rem conversion from 8px. This is equivalant to rem(8).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem8
///   // -> 0.5rem;
$rem8: #{8px / $rem}rem;

/// Rem conversion from 9px. This is equivalant to rem(9).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem9
///   // -> 0.5625rem;
$rem9: #{9px / $rem}rem;

/// Rem conversion from 10px. This is equivalant to rem(10).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem10
///   // -> 0.625rem;
$rem10: #{10px / $rem}rem;

/// Rem conversion from 11px. This is equivalant to rem(11).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem11
///   // -> 0.6875rem;
$rem11: #{11px / $rem}rem;

/// Rem conversion from 12px. This is equivalant to rem(12).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem12
///   // -> 0.75rem;
$rem12: #{12px / $rem}rem;

/// Rem conversion from 13px. This is equivalant to rem(13).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem13
///   // -> 0.8125rem;
$rem13: #{13px / $rem}rem;

/// Rem conversion from 14px. This is equivalant to rem(14).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem14
///   // -> 0.875rem;
$rem14: #{14px / $rem}rem;

/// Rem conversion from 15px. This is equivalant to rem(15).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem15
///   // -> 0.9375rem;
$rem15: #{15px / $rem}rem;

/// Rem conversion from 16px. This is equivalant to rem(16).
/// @group 300 - configuration
/// @access public
/// @example
///   $rem16
///   // -> 1rem;
$rem16: #{16px / $rem}rem;
