@import "~assets/styles/common";

.m-project-card-wrapper {
  padding: rem(20);
  background-color: $user-heading-tab-light;
  border-radius: rem(16);
  cursor: pointer;

  .m-logo-wrapper {
    @include flex;
    margin-bottom: rem(44);

    img {
      width: rem(48);
      height: rem(48);
      border-radius: $rem10;
    }
  }

  .m-name-icon-wrapper {
    .name {
      @include fsb(16);
    }
  }

  &.list {
    @include flex;

    &:not(:last-child) {
      margin-bottom: $rem12;
    }

    .m-logo-wrapper {
      @include flex;
      margin: 0 $rem12 0 0;

      img {
        width: rem(56);
        height: rem(56);
      }
    }

    .m-name-icon-wrapper {
      @include flex-center;
    }

    .right-arrow {
      @include flex;
      margin-left: auto;

      .name-arrow-right {
        @include flex(center);
      }

      svg {
        width: rem(24);
        height: rem(24);

        path:nth-child(2) {
          stroke: $cancel-button-color;
        }
      }
    }
  }

  &.grid {
    width: rem(155);
  }
}
