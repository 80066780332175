@import "~assets/styles/common";

.axiom-inline-edit-container {
  .edit-wrapper {
    .container {
      width: max-content;

      .tooltipContent.top {
        bottom: calc(100% + #{$rem8});
      }

      .text-wrapper {
        @include fsb(24);
        line-height: rem(36);
        color: $user-heading-tab-txt;
      }
    }

    .axiom-input-field {
      width: auto;
      height: rem(36);
      border: none;

      .axiom-input-text {
        @include fsb(22);
        line-height: rem(36);
        padding-left: 0;
        color: $user-heading-tab-txt;
        caret-color: $axiom-primary-color;
        border-radius: 0;
        border: none;
        border-bottom: $rem2 solid $transparent;

        &:focus {
          border-bottom: $rem2 solid $axiom-primary-color;
        }
      }
    }
  }
}
