@import "~assets/styles/common";

.profile-image {
  @include flex(center, center);
  border-radius: 50%;
  position: relative;

  .profile-image-container {
    @include flex(center, center);
    border-radius: 50%;
    background: $axiom-primary-color;
    color: $white-color;
    font-weight: bold;
  }
  &.large-image {
    height: rem(176);
    width: rem(176);
    border: rem(7) solid $close-btn-color;

    .profile-image-container {
      @include fm(44);
      height: rem(148);
      width: rem(148);
    }
  }
  &.small-image {
    height: rem(32);
    width: rem(32);
    border: rem(2) solid $close-btn-color;

    .profile-image-container {
      @include fm(10);
      height: rem(24);
      width: rem(24);
    }
  }
}

.profile-icon {
  display: inline-block;
  position: relative;

  .profile-icon-container {
    @include fsb(18);
    @include flex(center, center);
    height: rem(68);
    width: rem(68);
    border-radius: 50%;
    color: $black-color;
    opacity: 0.3;
    background: $cancel-button-color;
  }
}
.profile-clear-icon {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  height: rem(20);
  width: rem(20);
}
