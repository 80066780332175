@import "~assets/styles/common";

.axiom-add-button-container {
  @include flex-center;

  &.hide {
    visibility: hidden;
  }

  .sort-wrapper {
    .axiom-add-user-role-container {
      width: auto;
      border: none;
      margin-right: rem(16);

      .axiom-user-role-value {
        .arrow {
          pointer-events: none;
          position: static;
          display: inline-block;
          margin-left: $rem14;

          svg {
            width: $rem14;
          }

          .name-arrow-down,
          .name-arrow-up {
            @include flex-center;
          }

          .name-arrow-up {
            svg {
              position: relative;
              top: rem(-1);

              path {
                fill: $signin-heading-color;
              }
            }
          }
        }
      }

      .axiom-list-container.dropdown-list {
        border-radius: $rem8;
        overflow: visible;

        &::after {
          content: "";
          position: absolute;
          width: 0;
          height: 0;
          top: rem(-6);
          right: rem(20);
          border: rem(6) solid transparent;
          border-top: none;
          border-bottom: rem(6) solid $white-color;
        }
      }

      .inner-box {
        position: static;
      }
    }
  }
}
